import { useNavigate } from "react-router-dom";

function Sec1() {
    const navigate = useNavigate()
    const brandName = 'BBit.ink'
    return (
        <div className="flex flex-col items-center justify-center px-5 py-20 space-y-16">
            <h1 className="text-[40px] text-blue-500 font-semibold text-center">{brandName} is a crypto exchange for everyone</h1>
            <div className="flex flex-row w-full items-center justify-evenly xl:w-[80%]">
                <Sm top="10M+" bot="Clients" />
                <Sm top="190+" bot="Countries supported" />
                <Sm top="$207B+" bot="Quarterly trading volume" />
            </div>

            <div className="flex flex-col items-center justify-center lg:flex-row lg:space-x-[30px] lg:space-x-[120px] space-y-10 lg:space-y-0">
                <div className="max-w-[480px] bg-blue-50 p-5 rounded-xl flex "> 
                    <img src="dash.png" alt="" className="rounded-xl" />
                </div>
            
                <div className="flex flex-col justify-center">
                    <span className="font-semibold">CRYPTO EXCHANGE </span>
                    <span className="text-[40px] font-semibold text-blue-500">Buy crypto in minutes</span>
                    <div className="text-[20px] font-femibold flex flex-col space-y-4 my-6">
                        <Ul top="1" bot={`Create your free ${brandName} account`} />
                        <Ul top="2" bot="Connect your funding method" />
                        <Ul top="3" bot="Buy and sell 200+ cryptocurrencies" />
                    </div>
                    <button className="btn2 btn2-blue border-none w-[120px] py-3" onClick={() => navigate('/login')}>Buy crypto</button>
                </div>
            </div>
        

        </div>
    );
}
  
export default Sec1;

function Sm({top, bot}) {

    return (
        <div className="flex flex-col items-center space-y-4">
            <span className="text-[40px] text-blue-500 font-semibold">{top}</span>
            <span>{bot}</span>
        </div>
    )
}

function Ul({top, bot}) {
    return (
        <div className="flex flex-row space-x-3 items-center">
            <div className="border-2 border-blue-500 text-blue-500 rounded-full w-10 h-10 flex items-center justify-center">
                {top}
            </div>
            <span>{bot}</span>
        </div>
    )
}


  