import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Layout from './Layout'
import { coinsData } from '../../lib/dbInfo';
import { dateToUnixTime, getHistoryCoin, subtractDays, formatNumberWithCommas, calculatePercentageDifference } from '../../lib/funcs';
import Line from '../../components/charts/Line'
import RangeSelectorTab from '../../components/coin/RangeSelectorTab';
import { GrBitcoin } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { getData } from '../../lib/apiHandler';
import { useRecoilState } from 'recoil';
import { currentUser0, balance0, drawerData0, drawerSend0, notiList0 } from '../../lib/states';

import { CgArrowTopRight } from "react-icons/cg";
import { CgArrowBottomRight } from "react-icons/cg";
import { ImArrowUpRight2 } from "react-icons/im";
import { ImArrowDownRight2 } from "react-icons/im";

import { format } from 'date-fns';

function Coins() {
    const { coinId } = useParams();
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [balance, setBalance] = useRecoilState(balance0)
    const [drawerData, setDrawerData] = useRecoilState(drawerData0)
    const [drawerSend, setDrawerSend] = useRecoilState(drawerSend0)
    const [notiList, setNotiList] = useRecoilState(notiList0)

    const navigate = useNavigate();
    const [theCoin, setTheCoin] = useState({
        Icon: GrBitcoin,
        color: "#ff9b22",
        ids: 0,
        key: "",
        name: "",
    })

    const [tabState, setTabState] = useState(0)

    const [alltrans, setAllTrans] = useState([])
    const [trans, setTrans] = useState(null)

    const getTrans = async () => {
        const result = await getData('/transaction', `?userId=${currentUser.id}&coinKey=${coinId}`)
        if (result.ok) {
            setAllTrans(result.data.transactions)
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    useEffect(() => {
        getTrans()
    }, [drawerSend])

    useEffect(() => {
        setAllTrans([])
        setTrans(null)
        setTabState(0)
        getTrans()
    }, [coinId])

    useEffect(() => {
        setTrans(alltrans.filter((tran) => {
            if (tabState === 0) return tran;
            if (tabState === 1 && tran.usdAmount >= 0) return tran;                                        
            if (tabState === 2 && tran.usdAmount < 0) return tran;
        }))
    }, [tabState, alltrans])

    const [data, setData] = useState([])

    useEffect(() => {
        if (coinId !== null) {
            const coinIndex = coinsData.findIndex(data => data.key === coinId)
            if (coinIndex === null) {
              navigate('/')
            } else {
                setTheCoin({...coinsData[coinIndex], ids: coinIndex})
            }
        }
    }, [coinId])

    useEffect(() => {
        const getData = async () => {
            const start = dateToUnixTime(subtractDays(new Date(), 365))
            const result = await getHistoryCoin(start, dateToUnixTime(new Date()), theCoin.ids)
            setData(result)
        }
        if (theCoin.key !== '') {
            getData()
        }
    }, [theCoin])

    const monDiff = data.length !== 0 ? data[data.length-1].pv - data[0].pv : 0;

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const formattedDate = format(date, "dd MMM yyyy");
        return formattedDate;
    };
    const formatTime = (inputDate) => {
        var defaultDate = new Date(`2000-01-01T${inputDate}`);
        const formattedDate = format(defaultDate, "h:mm");
        return formattedDate;
    };

    const [address, setAddress] = useState('------------')
    const getPublicKey = async () => {
        const result = await getData(`/crypto/${theCoin.key}`, '')
        if (result.ok) {
            setAddress(result.data.data.publicKey)
        }
    }
    useEffect(() => {
        if (theCoin.key !== '') {
            getPublicKey();
        }
    }, [theCoin])


    const colorStatus = {
        'Pending': 'lightgray',
        'Confirmed': 'green',
        'Transaction declined': 'red'
    }


    const [temp, setTemp] = useState(0)

    useEffect(() => {
        if (data.length !== 0) {
            setTemp(formatNumberWithCommas(data[data.length-1].pv))
        }
    }, [data])

    
  return (
    <div className='flex flex-col w-full space-y-7'>
        <div className='space-y-2'>
            <div className='flex flex-col space-y-2 lg:space-y-0 lg:flex-row justify-between'>
                <div className='flex flex-row items-center space-x-3'>
                    <theCoin.Icon className="text-[36px]" style={{color: theCoin.color}} />
                    <h1 className='text-[30px] font-semibold text-gray-700'>{theCoin.name}</h1>
                </div>
                <div className='flex [&>button]:btn [&>button]:w-1/2 flex-row space-x-2 w-full lg:w-[250px]'>
                    <button className='btn-blue' onClick={() => setDrawerData(true)}>Receive</button>
                    <button className='btn-emp' onClick={() => setDrawerSend(temp)}>Send</button>
                </div>
            </div>
            <div className=''>
                <span className='text-gray-500 text-[18px]'>
                    {theCoin.slug}
                </span>
            </div>
            <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4'>
                <div className='con w-full lg:w-[25%] flex flex-col space-y-1'>
                    <span className='font-semibold text-gray-400 text-[18px]'>
                        {theCoin.key} Balance
                    </span>
                    <span className='text-2xl font-semibold text-gray-700'>
                        ${balance[coinId].usd}
                    </span>
                    <span className='font-semibold text-gray-700'>
                        {
                            parseFloat(Number.parseFloat(balance[coinId].coin).toFixed(8)).toString() + ' ' +  theCoin.key
                        }
                    </span>
                    {/* <span className="font-medium text-blue-600 cursor-pointer hover:underline" onClick={() => setDrawerData(true)}>
                        Get a wallet address
                    </span> */}
                </div>


                {/* Right */}
                <div className='con px-0 flex flex-row w-full lg:w-[75%] overflow-hidden'>
                    <div className='flex flex-col space-y-1 pl-[16px] min-w-[240px]'>
                        <span className='font-semibold text-gray-400 text-[18px]'>
                            {theCoin.key} Price
                        </span>
                        <span className='text-2xl font-semibold text-gray-700'>
                            ${data.length !== 0 && formatNumberWithCommas(data[data.length-1].pv)}
                        </span>
                        <div className='flex flex-row space-x-[6px]'>
                            <span className='font-semibold' style={{color: monDiff >= 0 ? '#00994C' : 'red'}}>
                                ${data.length > 0 && formatNumberWithCommas((data[data.length-1].pv - data[0].pv).toFixed(2))} ({data.length > 0 && calculatePercentageDifference(data[0].pv, data[data.length-1].pv)})%
                            </span>
                            <span className='text-gray-600'>
                                this year
                            </span>
                        </div>
                    </div>
                    <div className='h-[93.6px] min-w-[200px] w-[calc(100%-240px)]' >
                        <Line data={data} choosedCoin={theCoin.ids} />
                    </div>
                </div>
            </div>
        </div>


        <div className='space-y-7'>
            <RangeSelectorTab tabState={tabState} setTabState={setTabState} />
            <div className='con p-0 overflow-x-hidden'>
                {/* <Drawer>
                    <h1>dfjshfjkhk</h1>
                </Drawer> */}
                <table className='w-full text-gray-700'>
                    <tbody>
                        {
                            trans === null ? (
                                <tr><td className=''></td></tr> //// seklton
                            ) : (
                                trans.length === 0 ? (
                                    <tr className='h-[86px]'>
                                        <td>
                                            <span className='font-semibold'>No transactions</span>
                                        </td>
                                    </tr>
                                ) : (
                                    trans.map((tran, i) => (
                                        <tr key={i} className='[&>td]:py-1 h-[86px] border-b border-gray-100'>
                                            <td className={`w-[10px] px-6`} style={{color: colorStatus[tran.status]}}>
                                                {
                                                    tran.usdAmount >= 0 ? (
                                                        <ImArrowUpRight2 className='' />
                                                    ) : (
                                                        <ImArrowDownRight2 className='' />
                                                    )
                                                }
                                            </td>
                                            <td className='px-0 min-w-[85px]'>
                                                <div className='flex flex-col'>
                                                    <span className='font-semibold'>
                                                        {tran.usdAmount >= 0 ? 'Received' : 'Sent'} {theCoin.key}
                                                    </span>
                                                    <span className='text-sm text-gray-500 font-semibold'>
                                                        {formatTime(tran.time)}
                                                        {' '}
                                                        {formatDate(tran.date)}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className='px-0 w-max hidden xl:table-cell text-gray-400'>
                                                <div className='flex flex-col justify-center flex-sm font-semibold text-sm'>
                                                    <span><span className='font-bold'>From:</span> {tran.from}</span>
                                                    <span><span className='font-bold'>To:</span> {tran.to}</span>
                                                </div>
                                            </td>
                                            <td className='px-0 w-max hidden lg:table-cell text-gray-40]'>
                                                <div className='flex flex-col justify-center flex-sm font-semibold text-sm'>
                                                    {
                                                        typeof tran.description === typeof 'a' && tran.description !== '' && (
                                                            <span><span className='font-bold'>Description:</span> {tran.description}</span>
                                                        )
                                                    }
                                                    <span><span className='font-bold'>Status:</span> {tran.status}</span>
                                                </div>
                                            </td>
                                            <td className='pr-4 w-min max-w-[50px] min-w-[130px]'>
                                                <div className='flex flex-col text-end'>
                                                    <span className='font-semibold'>{tran.keyAmount} {theCoin.key}</span>
                                                    <span>${tran.usdAmount < 0 ? -tran.usdAmount.toFixed(2) : tran.usdAmount.toFixed(2)}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default Coins;
