import { useEffect, useState, useRef } from "react";
import { delData, putData } from "../../lib/apiHandler";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { MdKeyboardReturn } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { useRecoilState } from 'recoil';
import { notiList0 } from '../../lib/states';

function SimpleTable({data, ths, route, onChan}) {

    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th></th>
                {
                    ths.map((th,i) => (
                    <th key={i} scope="col" className="px-1 py-3">{th}</th>
                    ))
                }
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {
                data !== null && data.map((line, z) => (
                    <Tr key={z} line={line} route={route} onChan={onChan} ths={ths} />
                ))
            }
            </tbody>
        </table>
    );
}
  
export default SimpleTable;


const objType = {
    keyAmount: 'text',
    usdAmount: 'text',
    description: 'text',
    to: 'text',
    from: 'text',
    status: 'text',
    date: 'date',
    time: 'time'
}


function convertToHtml5Date(isoDate) {
    var dateObject = new Date(isoDate);
    dateObject.setHours(dateObject.getHours() + 3);
    var html5Date = dateObject.toISOString().split('T')[0];
    return html5Date;
}


function Tr({line, route, onChan, ths}) {

    const [notiList, setNotiList] = useRecoilState(notiList0)
    const [isEdit, setIsEdit] = useState(false)
    const ref = useRef();

    const handleRemove = async () => {
        const result = await delData(route + line['id'])
        if (result.ok) {
            onChan()
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    const opt = [
        'Pending',
        'Confirmed',
        'Transaction declined'
    ]

    const updateEdit = async () => {
        var newObj = {}
        if (ths.includes('privateKey')) {
            newObj = {
                ...line,
                publicKey: ref.current.querySelectorAll('input')[0].value,
                privateKey: ref.current.querySelectorAll('input')[1].value,
            }
        } else {
            newObj = {
                ...line,
                keyAmount: ref.current.querySelectorAll('input[name="keyAmount"]')[0].value,
                usdAmount: ref.current.querySelectorAll('input[name="usdAmount"]')[0].value,
                status: ref.current.querySelectorAll('select[name="status"]')[0].value,
                date: new Date(ref.current.querySelectorAll('input[name="date"]')[0].value),
                time: ref.current.querySelectorAll('input[name="time"]')[0].value,
                to: ref.current.querySelectorAll('input[name="to"]')[0].value,
                from: ref.current.querySelectorAll('input[name="from"]')[0].value,
                description: ref.current.querySelectorAll('input[name="description"]')[0].value,
            }
        }

        const result = await putData(route, newObj)
        if (result.ok) {
            onChan()
            setIsEdit(false)
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    return (
        <tr className="border-b dark:border-gray-700" ref={ref}>
            {
                isEdit ? (
                    <>

                        <td className="px-0">
                            <button type="button" onClick={() => setIsEdit(prevValue => !prevValue)} className="btn-icon text-blue-500 flex justify-center items-center">
                                <MdKeyboardReturn  />
                            </button>
                        </td>    

                        {
                            ths.map((th,i) => (
                                <td className={`px-1 py-3`} key={i}>
                                    {
                                        th !== 'coinKey' ? (
                                            
                                            th === 'status' ? (
                                                <select className="inp" name={th} defaultValue={line[th]}>
                                                    {
                                                        opt.map((op, o) => (
                                                            <option key={o} value={op}>
                                                                {op}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            ) : (
                                                <input className="inp" type={objType[th]} defaultValue={th === 'date' ? convertToHtml5Date(line[th]) : line[th]} name={th} />
                                            )
                                            
                                        ) : (
                                            line[th]
                                        )
                                    }
                                </td>
                            ))
                        }
                        <td className="px-0">
                            <button type="button" onClick={() => updateEdit()} className="btn-icon text-blue-500">
                                <IoMdSave  />
                            </button>
                        </td>    
                    </>
                ) : (
                    <>
                        <td className="px-0">
                            {
                                typeof line.id !== 'undefined' && (
                                    <button type="button" onClick={() => setIsEdit(prevValue => !prevValue)} className="btn-icon">
                                        <MdModeEditOutline  />
                                    </button>
                                )
                            }
                        </td>
                        {
                            ths.map((th,i) => (
                                <td className={`px-4 py-3`} key={i}>{th === 'date' ? convertToHtml5Date(line[th]) : line[th]}</td>
                            ))
                        }    
                        <td className="px-0">
                            {
                                typeof line.id !== 'undefined' && (
                                    <button type="button" onClick={() => handleRemove()} className="btn-icon btn-icon-red">
                                        <FaRegTrashAlt />
                                    </button>
                                )
                            }
                        </td>    
                    </>
                )
            }
        </tr>
    )
}


