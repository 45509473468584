import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {formatNumberWithCommas} from '../../lib/funcs'
import { coinsData } from '../../lib/dbInfo'

const data0 = [
  {
    date: `Nov 02, '23`,
    pv: 2400,
  },
  {
    date: `Nov 02, '23`,
    pv: 35826.67,
  },
  {
    date: `Nov 02, '23`,
    pv: 9800,
  },
  {
    date: `Nov 02, '23`,
    pv: 3908,
  },
  {
    date: `Nov 02, '23`,
    pv: 4800,
  },
  {
    date: `Nov 02, '23`,
    pv: 3800,
  },
  {
    date: `Nov 02, '23`,
    pv: 4300,
  },
];

export default function Example({data, choosedCoin}) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={300} data={data}>
        <Tooltip content={<CustomTooltip />} />
        {/* <YAxis domain={[25000]} /> */}
        <YAxis hide domain={['dataMin', 'dataMax']} />
        <Line type="monotone" dataKey="pv" stroke={coinsData[choosedCoin].color} strokeWidth={2} dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}



const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip flex flex-col items-center bg-[#121D33] text-white px-2 py-1 rounded-md text-sm space-y-[3px]">
        <p>{formatCustomDate(new Date(data.date * 1000))}</p>
        <p>${formatNumberWithCommas(data.pv)}</p>
      </div>
    );
  }

  return null;
};


function formatCustomDate(date) {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  
  const year = date.getFullYear() % 100;
  const month = months[date.getMonth()];
  const day = date.getDate();

  const formattedDate = `${month} ${day.toString().padStart(2, '0')}, '${year.toString().padStart(2, '0')}`;

  return formattedDate;
}