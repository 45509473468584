import { useNavigate } from "react-router-dom";
import { Logo } from "../../App";

function Header() {
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-blue-500 h-[100px] px-5 md:px-10 flex flex-row justify-between items-center text-white">
                <div>
                    <Logo />
                </div>
                <div className="flex flex-row space-x-2">
                    <button className="btn2 btn2-blue" onClick={() => navigate('/login')}>Sign in</button>
                    <button className="btn2 btn2-emp" onClick={() => navigate('/register')}>Sign up</button>
                </div>
            </div>

            <div className="bg-blue-50 flex justify-center items-center px-5 py-20">
                <div className="flex flex-col md:flex-row md:space-x-[30px] lg:space-x-[100px]">

                <div className="flex flex-col justify-center max-w-[482px] space-y-6">
                    <span className="text-[50px] font-semibold">Invest in your future</span>
                    <span className="text-[25px] font-femibold">Grow your portfolio in a more fair and open financial system. <span className="font-semibold">Start trading crypto with just $10.</span></span>
                    <button className="btn2 btn2-blue border-none w-[120px] py-3" onClick={() => navigate('/register')}>Sign up</button>
                </div>

                <div className="hidden md:flex relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[500px] w-[250px]">
                    <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                    <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                    <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                    <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                    <div className="rounded-[2rem] overflow-hidden w-[222px] h-[472px] bg-white dark:bg-gray-800">
                        <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-light.png" className="w-[222px] h-[472px]" alt="" />
                    </div>
                </div>
                
                </div>
            </div>
        </>
    );
}
  
export default Header;
  