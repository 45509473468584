import { delData, getData, postData } from "../../lib/apiHandler";
import { useRecoilState } from 'recoil';
import { notiList0, messages0 } from '../../lib/states';
import { useEffect, useState, useRef } from "react";
import { format } from 'date-fns';
import { FaChevronRight } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";

export default function ChatApp({userId, user, isAdmin}) {
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const [messages, setMessages] = useRecoilState(messages0)

    useEffect(() => {
        if (isAdmin) {
            getMessages()
            const intervalId = setInterval(() => {
              getMessages();
            }, 5000);
            return () => clearInterval(intervalId);
        }
      }, []);
    


    
    const [messCount, setMessCount] = useState(0)
    const [mesText, setMesText] = useState('')

    const chatRef = useRef(null);

    useEffect(() => {
        setMessCount(messages.length)
        if (messCount !== messages.length) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
            updateIsRead()
        }

    }, [messages]);

    const updateIsRead = async () => {
        const result = await getData(`/chat/isRead?userId=${userId}&panel=${isAdmin}`, '')
        if (result.ok) {
            getMessages()
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    

    
  
    const getMessages = async () => {
        const result = await getData(`/chat?userId=${userId}`, '')
        if (result.ok) {
            setMessages(result.data.messages)
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }
    





    const sendMsg = async (e) => {
        e.preventDefault();
        var formData = new FormData(e.target);
        var obj = {};
        formData.forEach((value, key) => obj[key] = value);
        obj['isAdminSend'] = isAdmin;
        //console.log(obj)
        const result = await postData(`/chat?userId=${userId}`, obj)
        if (result.ok) {
            getMessages()
            setMesText('')
            e.target.reset();
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }




    return (
        <div className="h-full flex flex-col">
            <div ref={chatRef} className="flex flex-col px-1 overflow-y-scroll space-y-3 py-4 h-full scrollbar-thin scrollbar-thumb-blue-200 scrollbar-track-gray-100">
                {
                    messages.map((message, m) => (
                        <div key={m} className="">
                            <Bob message={message} user={user} isAdmin={isAdmin} getMessages={getMessages} />
                        </div>
                    ))
                }
            </div>
            <form className="flex flex-row h-[50px]" onSubmit={sendMsg} autoComplete="off">
                <input value={mesText} onChange={(e) => setMesText(e.target.value)} className={`w-full px-2 outline-none focus:outline-none border-t focus:border-blue-500`} placeholder="Message" name="msg" type="text" />
                <button className="btn btn-blue h-full rounded-l-none disabled:bg-blue-300" type="submit" disabled={mesText === ''}><FaChevronRight /></button>
            </form>
        </div>
    )
}




function Bob({message, user, isAdmin, getMessages}) {
    const [notiList, setNotiList] = useRecoilState(notiList0)

    const removeMess = async () => {
        const result = await delData(`/chat?messageId=${message.id}`)
        if (result.ok) {
            getMessages()
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}])
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    return (
        <div className={`flex ${message.isAdminSend === 0 ? 'flex-row' : 'flex-row-reverse'} gap-2.5`}>
            <div className="flex flex-col items-center space-y-2">
                <div className={`w-8 h-8 rounded-full ${message.isAdminSend === 1 ? 'bg-gray-100' : 'bg-blue-100'}  flex items-center justify-center font-semibold text-gray-700`}>
                    <span>
                        {
                            user !== null && (
                                message.isAdminSend === 0 ? user.firstName.substring(0,1)+user.lastName.substring(0,1) : 'S'
                            )
                        }
                    </span>
                </div>
                    {
                        isAdmin && (
                            <button onClick={removeMess} className="btn-icon btn-icon-red w-7 h-7 flex items-center justify-center"><FaRegTrashAlt className="w-3" /></button>
                        )
                    }
            </div>
            <div className={`flex flex-col  leading-1.5 p-4 border-gray-200 max-w-[85%] ${message.isAdminSend === 1 ? 'bg-gray-100 rounded-b-xl rounded-tl-xl' : 'bg-blue-100 rounded-e-xl rounded-es-xl'}`}>
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                    <span className="text-sm font-semibold text-gray-900">
                        {
                            user !== null && (
                                message.isAdminSend === 1 ? 'Support' : user.firstName + ' ' + user.lastName
                            )
                        }
                    </span>
                    <span className="text-sm font-normal text-gray-500 ">{format(new Date(message.date), 'yyyy-MM-dd HH:mm')}</span>
                </div>
                <p className="text-sm font-normal py-2.5 text-gray-900 break-words ">{message.msg}</p>
                {
                    isAdmin && message.isAdminSend === 1 && (
                        message.isRead === 1 ? (
                            <span className="text-sm font-normal text-gray-500">Seen</span>
                        ) : (
                            <span className="text-sm font-normal text-gray-500">unread</span>
                        )
                    )
                }
            </div>
        </div>
    )
}