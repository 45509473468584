import {atom} from 'recoil';

export const currentUser0 = atom({
    key: 'currentUser0',
    default: null,
});

export const isAdmin0 = atom({
    key: 'isAdmin0',
    default: null,
});

export const notiList0 = atom({
    key: 'notiList0',
    default: [
        // {text: 'adsdasf',
        // isSucc: true}
    ]
});


export const usersList0 = atom({
    key: 'usersList0',
    default: null,
});


export const drawerData0 = atom({
    key: 'drawerData0',
    default: null,
});

export const drawerSend0 = atom({
    key: 'drawerSend0',
    default: null,
});

export const loc0 = atom({
    key: 'loc0',
    default: null,
});



export const balance0 = atom({
    key: 'balance0',
    default: {
        "BTC": {
          "coin": 0,
          "usd": 0
        },
        "ETH": {
            "coin": 0,
            "usd": 0
        },
        "USDT": {
            "coin": 0,
            "usd": 0
        },
        "XRP": {
            "coin": 0,
            "usd": 0
        },
        "all": 0
      }
});


export const messages0 = atom({
    key: 'messages0',
    default: [],
});

export const messagesAll0 = atom({
    key: 'messagesAll0',
    default: [],
});


export const popWin0 = atom({
    key: 'popWin0',
    default: {
        disable: false,
        msg: '',
        func: () => {}
    },
});


export const regLevel0 = atom({
    key: 'regLevel0',
    default: 0,
});


