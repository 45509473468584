import { useNavigate } from "react-router-dom"
import { FaBtc } from "react-icons/fa";
import { Logo } from "../../App";

export default function HeaderLog() {
    const navigate = useNavigate()
    
    return (
        <div className="cursor-pointer flex flex-row items-center text-white" onClick={() => navigate('/')}>
          <span className={`bg-white rounded-full px-3 py-[2px]`}>
              <Logo color="text-blue-800" />
          </span>
        </div>
    )
}