import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { notiList0, currentUser0, messages0 } from '../../lib/states';
import { AiFillMessage } from "react-icons/ai";
import ChatApp from './ChatApp';
import { getData } from '../../lib/apiHandler';
import { motion, AnimatePresence } from 'framer-motion';


export default function MessBub() {
  const [show, setShow] = useState(false)
  const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
  const [messages, setMessages] = useRecoilState(messages0)
  const [notiList, setNotiList] = useRecoilState(notiList0)

  const getMessages = async () => {
    const result = await getData(`/chat?userId=${currentUser.id}`, '')
    if (result.ok) {
        setMessages(result.data.messages)
    } else {
        setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
  }
  useEffect(() => {
    getMessages()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      getMessages();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className='flex flex-col space-y-1 items-end fixed left-auto bottom-4  right-4 sm:right-5 sm:bottom-5 z-40 '>
      <AnimatePresence>
        {
          show && (
          <motion.div 
            initial={{ opacity: 0, scale: 1, y: 200 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 1, y: 200 }}
            className='con p-0 flex flex-col relative w-[400px] h-[450px]'
          >

            <div className='h-[50px] bg-blue-500 flex-none rounded-t-lg flex items-center px-2'>
              <span className='text-white'>
                Chat Support
              </span>
            </div>
            <div className='grow h-14'>
              <ChatApp userId={currentUser.id} user={currentUser} isAdmin={false} />
            </div>

          </motion.div>
          
          )
        }
      </AnimatePresence>
      <div onClick={() => setShow(prev => !prev)} className='cursor-pointer w-12 h-12 rounded-full flex items-center justify-center text-[22px] btn-blue'>
          <AiFillMessage />
          {
            !show && messages.filter(mess => mess.isAdminSend === 1 && mess.isRead === 0).length !== 0 && (
              <span className="top-0 start-9 absolute w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
            )
          }
      </div>
    </div>
  )
}
