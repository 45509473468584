import { coinsData } from "../../lib/dbInfo";
import { IoMdArrowDown } from "react-icons/io";

function Sec2() {
    return (

        <div className="bg-blue-50 flex justify-center items-center px-5 py-20">
            <div className="flex flex-col lg:flex-row lg:space-x-[30px] lg:space-x-[100px] space-y-6 items-center">

                <div className="flex flex-col justify-center lg:max-w-[482px] space-y-6 text-center">
                    <span className="text-[50px] font-semibold line leading-[60px] text-blue-500">Explore crypto like Bitcoin, Ethereum, and Dogecoin</span>
                    <span className="text-[25px] font-femibold">Simply and securely buy, sell, and manage hundreds of cryptocurrencies.</span>
                </div>

                <div className="flex flex-wrap justify-center items-center max-w-[450px]">
                    {
                        coinsData.map((coin, i) => (
                            <div key={i} className="con w-[180px] h-[160px] rounded-xl flex flex-col justify-between m-4" >
                                <div className="flex flex-row items-center space-x-3">
                                    <coin.Icon className={`text-[38px]`} 
                                        style={{color: coin.color}}
                                        />
                                    <div className="flex flex-col space-y-0">
                                        <span className="font-semibold text-[20px]">{coin.name}</span>
                                        <span className="text-sm">$5,922,388.99</span>
                                    </div>                                        
                                </div>
                                <div className="text-red-600 text-2xl flex flex-row items-center justify-start">
                                    <IoMdArrowDown className="text-[29px]" />
                                    <span className="">0.99%</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            
            </div>
        </div>
    );
}
  
export default Sec2;
  