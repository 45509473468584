
import { coinsData } from "./dbInfo";
import { getData } from '../lib/apiHandler'


// export const urlServer = 'http://localhost:8000'
export const urlServer = 'https://api.coinx.live'

export function formatNumberWithCommas(number) {
    if (isNaN(number)) {
        return "Invalid number";
    }
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export async function getHistoryCoin(start, end, choosedCoin) {  
  var result = await getData('/data/fetch-history-coin', `?period1=${start}&period2=${end}&coin=${coinsData[choosedCoin].key}`)
  result = result.data
  if (result && result.chart && result.chart.result && result.chart.result[0] && result.chart.result[0].indicators && result.chart.result[0].indicators.quote) {
    const timeList = result.chart.result[0].timestamp
    const quotes = result.chart.result[0].indicators.quote[0].close;
    const final = quotes.map((item, z) => {
      if (quotes[z] == null) {
        quotes[z] = quotes[z-1]
      }
      return {
        pv: quotes[z].toFixed(coinsData[choosedCoin].key !== 'USDT' ? 2 : 5),
        date: timeList[z]
      }
    })
    return final
  }
}


export function dateToUnixTime(date) {
  return Math.floor(date.getTime() / 1000);
}

export function subtractDays(date, days) {
  const milliseconds30Days = days * 24 * 60 * 60 * 1000;
  const subtractedDate = new Date(date - milliseconds30Days);
  return subtractedDate;
}

export function calculatePercentageDifference(num1, num2) {
  if (num1 === 0) {
      throw new Error("Cannot calculate percentage difference when the first number is 0.");
  }
  const percentageDifference = ((num2 - num1) / num1) * 100;
  return percentageDifference.toFixed(2);
}

export function objectToQueryParams(obj) {
  const queryParams = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      queryParams.append(key, obj[key]);
    }
  }
  return queryParams.toString();
}

export function convertBitcoinToDollars(bitcoinAmount, bitcoinToUSDExchangeRate) {
  if (isNaN(bitcoinAmount) || isNaN(bitcoinToUSDExchangeRate)) {
    console.error('Invalid input. Please provide valid numbers.');
    return null;
  }

  const convertedAmount = bitcoinAmount * bitcoinToUSDExchangeRate;
  return convertedAmount.toFixed(10);
}

export function formDataToObject(formData) {
  let object = {};
  formData.forEach((value, key) => {
      object[key] = value;
  });
  return object;
};