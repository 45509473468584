import Layout from './Layout';
import ConHistory from '../../components/home/ConHistory';
import { coinsData } from '../../lib/dbInfo';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { balance0 } from '../../lib/states';

function Landing() {
  const [balance, setBalance] = useRecoilState(balance0)
    return (
      <>
        <div className='con py-0 w-full lg:w-[40%] h-min text-gray-700'>
          <h2 className='text-sm py-3 font-semibold'>Holdings</h2>
          <div>
            {
              coinsData.map((coin, i) => (
                (balance[coin.key].usd !== 0 || coin.key === 'BTC') && (
                  <Link key={i} to={`/coins/${coin.key}`}>
                      <Il 
                        balance={balance[[coin.key]]} 
                        coinKey={coin.key} 
                        icon={<coin.Icon className={`text-[24px]`} 
                        style={{color: coin.color}} />} 
                        text={coin.name} 
                        hov={coin.key === 'BTC' ? '#FF372240' : null} 
                      />
                  </Link>
                )
              ))
            }
          </div>
        </div>
        <div className='con p-0 space-y-2 w-full lg:w-[60%] h-min'>
          <ConHistory />
        </div>   
      </>
    );
}

export default Landing;

function Il({icon, text, balance, coinKey}) {
  return (
    <div 
        className={`flex flex-row items-center  py-[10px] rounded-md cursor-pointer justify-between`}
    >
        <div className='flex flex-row flex-row items-center  space-x-4'>
          <div>
            {icon}
          </div>
          <span className='text-md font-semibold'>
              {text}
          </span>
        </div>
        <div className='flex flex-col text-sm text-gray-500'>
          <span className='flex justify-end font-semibold'>${balance.usd}</span>
          <span className='text-xs flex justify-end'>{parseFloat(Number.parseFloat(balance.coin).toFixed(8)).toString()} {coinKey}</span>
        </div>
    </div> 
  )
}