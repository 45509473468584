import { useState } from "react";
import { FaBtc } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BrandName, MenuTerms } from "../../App";




const PolicyPage = () => {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);
    

    return (
        <div className="flex flex-col min-h-screen">


            {/* MENU */}
            <MenuTerms />


            <div>
                {/* SEC1 */}
                <div>
                    <div className={`${BrandName === 'Bwallet' ? 'bod2' : 'bod'} py-[120px] space-y-10`}>
                        <h1 className='text-king text-[32px] font-bold'>Privacy policy</h1>

{/* Our Privacy Policy covers all personal data that you submit to us or that we obtain from you when you use our Website or the Services. */}

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">

                            1.IMPORTANT INFORMATION

                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            The {BrandName} respects your privacy and values its importance, and is wholly committed to keeping your information safe and secure. We process your personal data in accordance with privacy laws and this Privacy Policy to make sure your data protection rights are implemented and enforceable. We have implemented different technical and organizational solutions to comply with applicable legislation related to the processing of personal data, privacy, and data security in countries where we operate or where the applicable law applies to us. The Privacy Policy sets forth the basic rules and principles by which we process your personal data, and mentions our responsibilities while processing your personal data according to transparency obligations.

We do not intend to collect personal data of children or persons under the age of 18 (eighteen) years old.

Accordingly, the purpose of this Policy is to set out the basis on which we will process your Personal data when:

You visit and use any page of our Website Platform, regardless of where you visit or use them from;
You apply for, receive, pay and/or use any of our Services.
You communicate with us or leave a query;
You subscribe to our newsletters/updates;
We need to share your personal data with the authorized third-parties to provide the services, especially for conducting KYC and AML procedure; and
We measure or analyze the Website’s traffic.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            2. COLLECTED CATEGORIES OF PERSONAL DATA AND WHY WE NEED IT

                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Depending on the type of Service you use, we might collect the following categories of personal data:
Your identification information necessary for your identification such as name, address, etc.;
Your phone number for authentication purposes;
Documents and information necessary for compliance with KYC and AML rules such as copies of your identification documents (passport, ID card, driving license or other documents for the compliance) and information from external sources such as public databases, credit bureaus, ID verification partners, resellers and channel partners, joint marketing partners, and social media platforms;
Data that identifies you such as your IP address, language, country, browser type, and version, time zone setting, browser plug-in types, some location information about where you might be, operating system and version;
Data on how you use the Website such as your URL clickstreams (the path you take through the Website), page response times, download errors, how long you stay on web pages, what you do on those pages, how often, and other actions;
other personal data you share with us or personal data that we may legally obtain from some sources.
Please note that we do not store your credit card information and KYC documents you submit for identification purposes, our payment service provider stores this information. Agreeing to this privacy policy gives our partners the right to collect and store these data

We never collect sensitive data. We do not use automated decision making or any kind of automated profiling. The recipients of the collected data are

The highest management level of our {BrandName} for whom it is necessary to process personal data for the functionality of the {BrandName} and
Third-party service providers


*OUR {BrandName} ANTI-MONEY LAUNDERING (AML) POLICY*


                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">

                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                                
                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            3. THIRD PARTY SERVICE PROVIDERS


                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            We have third party service providers which are indicated below. Depending on the Service you use, you might interact or your data might be shared with the following third part services providers:
Sum&Substance: for KYC/AML verification;
Twilio: for phone authentication;
Powercash21: card payment service provider;
Globitex: for SEPA payment;
Maxmind: services providing fraud-preventing and risk modeling tools;
Guardarian OU: for web-based virtual currency purchase;
Walletto UAB: for VISA prepaid card and/or virtual card services (offered via our partner Guardarian OU);
Website analytics companies to analyze data and improve our services and the Website such as Google Analytics which is a part of the EU-U.S. Privacy Shield Frameworks which allow to transfer personal data outside the EU;
Advertising companies for marketing purposes such as Google AdWords;
Social media companies to promote and be present in social media such as Facebook, Instagram, LinkedIn, Twitter, YouTube, Telegram, GitHub, Discord, Medium, Reddit;
Others.
You directly consent to sharing your personal data with these partners.

The Website may contain links to third-party sites. This Privacy Policy does not cover the privacy practices of such third parties. These third parties have their own privacy policies and we do not accept any responsibility or liability for their sites, features or policies. Please read their privacy policies before you submit any data to them.

Please familiarize yourself with these providers and their privacy and liability policies. If you find any of these may not work for you, please do not access any of the Websites and do not use any of our Services.



                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">

                            4. LEGAL GROUNDS FOR THE PROCESSING


                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            There are legal grounds necessary for the processing of personal data and we count on them to process your personal data. When we process personal data, we have obligations according to the applicable laws. We act as a data controller when we determine the purposes and means of the processing of your personal data. We use the main four grounds to process your personal data: consent, contract, legal obligation, and legitimate interests.

Consent – the freely given, informed, and unambiguous indication of your wishes to the processing of your personal data for a specific purpose which signifies agreement to the processing of personal data.

Contract – a legal ground for the processing of your personal data necessary for us to perform a contract or terms and conditions to which you are a party or in order to take steps at your request prior to entering into the contract or terms and conditions.

Legal obligations – a legal ground for the processing of your personal data when it is necessary for compliance with a legal obligation to which we are subject;

Legitimate Interests – a legal ground for the processing of your personal data when it is based on our legitimate interests or the legitimate interests of a third party, provided that those interests are not outweighed by your rights and interests and those interests have a specific purpose, they are necessary, and they are balanced.




                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            5. PURPOSES AND LEGAL BASIS FOR THE PROCESSING

                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            We process the data for:
DATA PROCESSING	LEGAL BASIS
Providing services. We need to provide services via the Website	Contract
Accepting payments and provision of the services	Contract
Providing newsletters/offers/updates which may be interesting to you	Consent for newsletters; Legitimate Interests for offers and updates.
Registering you as a user	Contract
Compliance with applicable anti-money laundering and know your client rules	Legal obligation
Keeping the Website running (managing your requests, remembering your settings, hosting, and back-end infrastructure)	Legitimate Interests
Preventing frauds, illegal activity, or any violation of the terms or Privacy Policy. We may disable access to the Website, erase or correct personal data in some cases	Legitimate Interests
Improving the Website (testing features, interacting with feedback platforms, managing landing pages, heat mapping the Website, traffic optimization, and data analysis and research, including profiling and the use of machine learning and other techniques over your data and in some cases using third parties to do this)	Legitimate Interests
Customer support (notifying you of any changes to the Website, services, solving issues, any bug fixing, communication with support)	Legitimate Interests
6.DATA SHARING
We may share your personal information with service providers listed above, including for the purpose of conducting audits or compliance checks related to our or their business operations. We require our partners to maintain the confidentiality and security of your personal information and use it only for the specific purpose for which it was shared.

We may record, monitor, or otherwise share communications between you and our support team for the purpose of quality control, training, improving our services and our service providers’ audit. By contacting our support team, you consent to our recording and monitoring of these communications and the potential sharing of information with our service providers.



                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">

                            7. YOU CAN EXERCISE THE FOLLOWING RIGHTS BY CONTACTING US.
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
You have the right to access information about you, especially:
The categories of data;
The purposes of data processing;
Third parties to whom the data disclosed;
How long the data will be retained and the criteria used to determine that period;
Other rights regarding the use of your data.
The right to access information may be performed only by you or your legal representative. In case if you request the right to access information via a legal representative, you have to provide proof of whether such a person may represent you.
You have the right to make us correct any inaccurate personal data about you.
You can object to using your personal data for profiling you or making automated decisions about you. We may use your data to determine whether we should let you know the information that might be relevant to you.
You have the right to the data portability of your data to another service or website. We will give you a copy of your data in a readable format so that you can provide it to another service. If you ask us and it is technically possible, we will directly transfer the data to the other service for you.
You have the right to be “forgotten”. You may ask us to erase any personal data about you if it is no longer necessary for us to store the data for purposes of your use of the Website. Please note that we cannot grant this request if the data was collected for regulatory purposes for example AML/CFT purposes.
You have the right to lodge a complaint regarding the use of your data by us. You can address a complaint to your national regulator (the list of the regulators are accessible via [https://edpb.europa.eu/about-edpb/board/members_en](https://edpb.europa.eu/about-edpb/board/members_en).
Once we receive any of your requests we will consider and decide on it within one month unless there is a justified requirement to provide such information faster. This term may be extended according to the applicable law. We may request specific information from you to confirm your identity when necessary and reasonable. This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. You do not need to pay a fee to access information or other rights but we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive or refuse to comply with your request in these circumstances.
8.RETENTION POLICY
We store personal data as long as we need it and the retention practice depends on the type of data we collect, regulatory burden, and how we use the personal data.

The retention period is based on criteria that include legally mandated retention periods, pending or potential litigation, tax and accounting laws, intellectual property or ownership rights, contract requirements, operational directives or needs, and historical archiving.

In some circumstances, we may anonymize your personal data for research or statistical purposes in which case we may use this information indefinitely without further notice to you. For the purposes of the KYC/AML, we will store and process your personal data for 5 (five) years.

9.SECURITY
We have security and organizational measures and procedures to secure the data collected and stored and to prevent it from being accidentally lost, used, altered, disclosed, or accessed without authorization. We allow disclosure of your personal data only to those employees and companies who have a business need to know such data. They will process the personal data on our instructions and they are obliged to do it confidentially.

You acknowledge that no data transmission is guaranteed to be 100% secure and there may be risks. You are responsible for your login information and password. You shall keep them.



                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            OBJECTIVE OF THE AML POLICY
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                                

1.1. Our Comapny staff is committed to pursue the highest standards of AML/CFT and Know Your Business (KYB) to mitigate the risk of Our Comapny services being used to facilitate financial crimes.
1.2. The {BrandName} has implemented the AML/CFT Policy to ensure that all the necessary procedures are taken in order to achieve full compliance.
AML POLICY
2.1. AML Policy is the procedure to prevent the Company’s services from being used for purposes of money laundering, terrorist financing or any other criminal activity. KYB measures are part of the AML Policy. The objective of KYB measures is to enable the {BrandName} to know their Partners and be able to mitigate the above mentioned risks. The {BrandName} may update such procedures in the future to assure the compliance with best AML practice.
MEASURES RELATED TO KYB AND SCREENING
3.1. The {BrandName} shall identify the Partner i.e. legal entities which are partnered with Our Comapny. Such Partners of the {BrandName} must finish an identification and verification procedure and provide all required information.
Corporate customers
Information collected	Purpose of collection of data
legal name	
to provide the Company’s services
to resolve the customer’s claims;
to communicate with the Partner;
Others (such as requests from the law-enforcement bodies etc.)
date of incorporation
Partner’s email
Partner’s place of incorporation
description and nature of the Partner’s business
3.2. The Partner shall provide the {BrandName} with following valid documents serve as basis for identification:
a high-resolution copy of the certificate of incorporation (an extract from the Commercial Register or equivalent document, evidencing the registration of the corporate customer);
names and addresses of all directors and beneficial owners of the corporate entity.
3.3. The {BrandName} shall identify the beneficial owners (UBOs) and, for the purpose of verifying their identities, taking measures to the extent that allows the {BrandName} to make certain that it knows who the beneficial owners are, and understands the ownership and control structure of the corporate customer, or of the person participating in the transaction. A representative of the Partner must submit a document certifying his/her powers (a power of attorney), which has been authenticated by a public notary and/or legalized and/or certified with an apostille, unless otherwise provided for in an international agreement.
3.4. All the information and documents provided by the Partner shall be completely clear and readable.
3.5. The Partner agrees to provide additional information and documents on the request of the {BrandName} within a reasonable period of time.
3.6. The {BrandName} reserves the right to impose additional due diligence requirements to accept the Partner residing in certain countries.
3.7. In case of any suspicious activity, false documents or non-cooperation by the Partner within the due diligence process, the {BrandName} is obligated to reject the Partner’s documents, close any existing accounts, and terminate the business relationship.
MONITORING
4.1 In addition to gathering information from the Partners, the {BrandName} may continue to monitor information on the Partner.
SANCTIONS POLICY
5.1. Our Comapny is prohibited from transacting with companies and countries that are on prescribed sanctions lists. The {BrandName} will therefore screen its Partner against international sanction lists on a periodical basis.



                            </p>
                        </div>







                    </div>
                </div>
            </div>    
        </div>
    )
}   


export default PolicyPage