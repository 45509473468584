import { createContext, useContext, useEffect, useState } from 'react';
import { getData } from './apiHandler';
import { useRecoilState } from 'recoil';
import { isAdmin0 } from './states';



const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [isAdmin, setIsAdmin] = useRecoilState(isAdmin0);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    const func = async () => {
      if (localStorage.getItem('token') !== null) {
        const result = await getData('/user/isAlive', '')
        if (result.ok) {
          setIsLoggedIn(true)
          if (result.data.isAdmin) {
            setIsAdmin(true)
          }
        } else {
          setIsLoggedIn(false)
          setIsAdmin(null)
          logout()
        }
      } else {
        setIsLoggedIn(false)
        setIsAdmin(false)
      }
    }
    func()
  }, [token])

  
  const login = (newToken, itsAdmin) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    if (itsAdmin) {
      setIsAdmin(true)
    }
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setIsAdmin(null)
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, isLoggedIn, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
