import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { notiList0, usersList0, messagesAll0 } from '../../lib/states';
import { getData } from '../../lib/apiHandler';
import { objectToQueryParams } from '../../lib/funcs';
import Table from '../../components/parts/Table';
import Address from '../../components/adminParts/Address';


const Panel = () => {
  const [notiList, setNotiList] = useRecoilState(notiList0)
  const [messagesAll, setMessagesAll] = useRecoilState(messagesAll0)


  const [data, setData] = useRecoilState(usersList0)
  const ths = ['id', 'mess' , 'firstName', 'lastName' , 'phone', 'email', 'approval', 'isAdmin'] //'isRegFees'
  const [filter, setFilter] = useState(
    {
      page_index: 1,
      per_page: 10
    }
  )
    

  const getUsersList = async () => {
    const result = await getData('/users', `?${objectToQueryParams(filter)}`)
    if (result.ok) {
      setData(result.data)
    } else {
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
  }

  useEffect(() => {
      getUsersList();
  }, [filter])


  const pageMoving = (index) => {
    setData(null)
    setFilter(prevValue => {
        return {
            ...prevValue,
            page: index
        }
    })
  } 
  const createRange = () => {
    var range = []
    for (var i=1; i<=data.page_amount; i++) {
        if (i === 1) range.push(i);
        else if (i === data.page_amount) range.push(i);
        else if (i === data.current_page) range.push(i);
        else if (i === data.current_page-1) range.push(i);
        else if (i === data.current_page+1) range.push(i);
        else range.push(true);
    }
    var newRan = range.filter((num, i) => {
        if (typeof num !== typeof true) return num;
        if (typeof range[i-1] !== typeof true) {
            return num
        }
    })

    var fullRan = []
    for (var i=1; i<=data.page_amount; i++) {
        fullRan.push(i);
    }
    if (newRan.length === range.length) return fullRan;
    return newRan;
  }
  return (
    <div className="mx-auto max-w-screen-xl px-4 lg:px-12 space-y-4">
      <div className='space-y-2'>
        <h1 className='text-xl font-semibold'>Users list</h1>
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <Table data={data} ths={ths} mess={messagesAll} />
            {
              data !== null && (
                <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                    <div className='flex flex-row items-center space-x-2'>
                      <select className='inp w-[60px]' value={filter.per_page} onChange={(e) => setFilter(prevValue =>{ return {...prevValue, per_page: e.target.value}})}>
                        {
                          [10,20,60,80,100].map(n => (
                            <option key={n} value={n}>{n}</option>
                          ))
                        }
                      </select>
                      <span className="text-sm font-normal text-gray-500 dark:text-gray-400 space-x-1">
                          <span>Showing</span>
                          <span className="font-semibold text-gray-900 dark:text-white">{data !== null ? data.from : 0}-{data !== null ? data.to : 0}</span>
                          <span>of</span>
                          <span className="font-semibold text-gray-900 dark:text-white">{data !== null ? data.total : 0}</span>
                      </span>
                    </div>
                    <ul className="inline-flex items-stretch -space-x-px">
                      {
                        filter.page_index !== 1 && 
                        <li onClick={() => pageMoving(filter.page_index-1)}>
                            <span className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </span>
                        </li>
                      }
                      {
                        createRange().map((key, i) => (
                            <li key={i} onClick={() => pageMoving(key)}>
                                <span style={{
                                    backgroundColor: filter.page_index === key ? '#eff6ff' : 'white'
                                }} className={`${filter.page_index !== key && 'cursor-pointer'} flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                >
                                    {
                                        typeof key === typeof 1 ? (
                                            key
                                        ) : (
                                            "..."
                                        )
                                    }
                                </span>
                            </li>
                        ))
                      }
                      {
                        filter.page_index !== data.page_amount && (
                            <li onClick={() => pageMoving(filter.page_index+1)}>
                                <span className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </li>
                        )
                      }
                    </ul>
                </nav>
              )
            }
        </div>
      </div>

      {/* <Address /> */}
    </div>
  );
};

export default Panel;
