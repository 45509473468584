import React, { useEffect, useState } from 'react';
import { delData, getData, postData, putData } from '../../lib/apiHandler';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SimpleTable from '../../components/parts/SimpleTable';
import { coinsData } from '../../lib/dbInfo'
import { urlServer } from '../../lib/funcs';
import { useRecoilState } from 'recoil';
import { notiList0, popWin0 } from '../../lib/states';
import ChatApp from '../../components/parts/ChatApp';


const UserPage = () => {
  const navigate = useNavigate()


  const [notiList, setNotiList] = useRecoilState(notiList0)

  const [user, setUser] = useState(null)
  const [listWall, setListWall] = useState(null)
  const { userId } = useParams();

  const getUser = async () => {
    setListWall(null)
    const result = await getData('/user/wallets', `?userId=${userId}`)
    if (result.ok) {
      setUser(result.data.user)
      setListWall(result.data.wallets)
    } else {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: false }])
    }
  }
  useEffect(() => {
    getUser()
  }, [])

  /////// transactions ///////
  const [show, setShow] = useState(false)
  const [dataTran, setData] = useState(null)

  const handleUpload = async (e) => {
    e.preventDefault()
    var formData = new FormData(e.target);
    var obj = {};
    formData.forEach((value, key) => obj[key] = value);
    obj['userId'] = userId;

    obj.status = "Pending"  

    const result = await postData('/transaction', obj)
    if (result.ok) {
      await getTransactions()
      setShow(false)
    } else {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: false }])
    }
  }

  const getTransactions = async () => {
    setData(null)
    const result = await getData('/transaction', `?userId=${userId}`)
    if (result.ok) {
      setData(result.data.transactions)
    } else {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: false }])
    }
  }

  const [balance, setBalance] = useState(0)
  const getBalance = async () => {
    const result = await getData('/transaction/all', `?userId=${userId}`)
    // console.log(result.data)
    if (result.ok) {
      setBalance(result.data.balance.all)

    } else {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: false }])
    }
  }

  useEffect(() => {
    getTransactions()
    getBalance()
  }, [])

  const [keyCoin, setKeyCoin] = useState('BTC')

  const [coinCurrency, setCoinCurrency] = useState(0)

  async function getCurrency() {
    var result = await getData('/data/coins-price', `?coin=${keyCoin}`)
    setCoinCurrency(result.data.currency)
  }

  const updateUser = async (e) => {
    e.preventDefault()
    var formObj = formDataToObject(new FormData(e.target));
    if (user.approval === 0 && formObj.approval === '1') {
      sendWelcome()
    }
    const result = await putData(`/user?userId=${userId}`, formObj)
    if (result.ok) {
      setUser(formObj)
      setEditMode(false)
    } else {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: false }])
    }
  }

  useEffect(() => {
    getCurrency()
  }, [keyCoin])

  const formDataToObject = (formData) => {
    let object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    return object;
  };

  const handleDelete = async () => {
    const result = await delData(`/user?userId=${userId}`)
    if (result.ok) {
      navigate('/panel')
    } else {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: false }])
    }
  }

  ///// edit user /////

  const [editMode, setEditMode] = useState(false)

  const [popWin, setPopWin] = useRecoilState(popWin0)



  //////////////////////// handle mini swap //

  const [dollarAmount, setDollarAmount] = useState('');
  const [bitcoinAmount, setBitcoinAmount] = useState('');

  const convertDollarsToBitcoin = () => {
    const dollars = parseFloat(dollarAmount);
    const convertedBitcoin = dollars / coinCurrency;
    if (isNaN(convertedBitcoin)) {
      setBitcoinAmount(0);
    } else {
      setBitcoinAmount(convertedBitcoin.toFixed(8));
    }
  };

  const convertBitcoinToDollars = () => {
    const bitcoin = parseFloat(bitcoinAmount);
    const convertedDollars = bitcoin * coinCurrency;
    if (isNaN(convertedDollars)) {
      setDollarAmount(0);
    } else {
      setDollarAmount(convertedDollars.toFixed(2));
    }
  };


  function convertStringToNumber(str) {
    console.log(str)
    const stringWithoutCommas = str.replace(/,/g, '');
    const number = parseFloat(stringWithoutCommas);
    return number;
  }

  const updateBanner = async (e) => {
    e.preventDefault();


    var obj = {
      userId: userId
    };
    var formData = new FormData(e.target);
    formData.forEach((value, key) => obj[key] = value);

    const result = await postData('/banner', obj)
    if (result.ok) {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: true }])
    } else {
      setNotiList(prevValue => [...prevValue, { text: result.data.message, isSucc: false }])
    }

  }
  const [currentTime, setCurrentTime] = useState(new Date().toISOString().slice(0, 16));


  const sendWelcome = async () => {
    const result = await getData(`/user/welcome-user?userId=${userId}`, )
    if (result.ok) {
      setNotiList(prevValue => [...prevValue, { text: "message sent!", isSucc: true }])
    } else {
      setNotiList(prevValue => [...prevValue, { text: "message didnt sent", isSucc: false }])
    }
  }

  const listKeys = ['BTC', 'ETH', 'USDT', 'XRP']

  const getPublicKey = async (coinKey0) => {
    const result = await getData(`/crypto/${coinKey0}?userId=${user.id}`, '')
    if (result.ok) {
        getUser()
    } else {
        setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
  }

  return (
    <div className='flex flex-col lg:flex-row space-x-4'>
      <div className='w-full lg:w-2/3  flex flex-col space-y-2'>
        <form className='w-full flex flex-col space-y-2' onSubmit={updateUser}>
          <div className='flex flex-row justify-between col-span-3'>
            <h1 className='text-2xl font-semibold'>
              User info
            </h1>
            {
              !editMode ? (
                <>
                  <button type="button" className='btn btn-blue h-10' onClick={() => setEditMode(prevValue => !prevValue)}>
                    Edit user
                  </button>
                </>
              ) : (
                <div className='flex flex-row space-x-2'>
                  <button className='btn btn-del h-10' type='button' onClick={() => {
                    setPopWin({
                      disable: true,
                      msg: 'Are you sure you want to delete This user?',
                      func: handleDelete
                    })
                  }}>
                    delete
                  </button>
                  <button className='btn btn-blue h-10' type='submit'>
                    save
                  </button>
                  <button type="button" className='btn btn-emp rounded-full h-10' onClick={() => setEditMode(prevValue => !prevValue)}>
                    X
                  </button>
                </div>
              )
            }
          </div>

          <div className='grid col-span-3 grid-cols-3 gap-4 h-min'>
            <Rib editMode={editMode} cot='firstName' text={user !== null ? user.firstName : ''} />
            <Rib editMode={editMode} cot='lastName' text={user !== null ? user.lastName : ''} />
            <Rib editMode={editMode} cot='email' text={user !== null ? user.email : ''} />

            <Rib editMode={editMode} cot='phone' text={user !== null ? user.phone : ''} />
            {/* <Rib editMode={editMode} cot='isRegFees' text={user !== null ? user.isRegFees : '' } isList={true} /> */}
            <Rib editMode={editMode} cot='approval' text={user !== null ? user.approval : ''} isList={true} />

            <Rib cot='balance' text={balance} />
            <Rib editMode={editMode} cot='isAdmin' text={user !== null ? user.isAdmin : ''} isList={true} />
            <Rib editMode={editMode} cot='password' text={''} />

            <Rib editMode={false} cot='file' text={

              user?.IdFile !== '' ? (
                <Link to={`${urlServer}/uploads/${user?.IdFile}`} target="_blank">click here</Link>
              ) : (
                <span>no file</span>
              )

            } />

          </div>
        </form>

        <div>
          
          <div className='col-span-3 lg:col-span-2 h-min space-y-2'>
            <div className='flex flex-row justify-between'>
              <h1 className='text-2xl font-semibold'>
                Transaction record
              </h1>
              {
                !show ? (
                  <button type="button" className='btn btn-blue h-10' onClick={() => setShow(prevValue => !prevValue)}>
                    Add Transaction
                  </button>
                ) : (
                  <button type="button" className='btn btn-emp rounded-full h-10' onClick={() => setShow(prevValue => !prevValue)}>
                    X
                  </button>
                )
              }
            </div>
            {
              show && (
                <form className='con space-y-2' onSubmit={handleUpload}>
                  <div className='flex flex-row space-x-2'>
                    <input className='inp' name='date' type='date' defaultValue={currentTime.substr(0, 10)}  required />
                    <input className='inp' name='time' type='time' defaultValue={currentTime.substr(11, 5)} required />
                  </div>
                  <input className='inp' name='description' type='text' placeholder='Description' required />
                  <input className='inp' name='from' type='text' placeholder='from Address' />
                  <input className='inp' name='to' type='text' placeholder='to Address' defaultValue={listWall?.find((wal,w) => wal.coinKey === keyCoin).publicKey} required />
                  <div className='flex flex-row space-x-2'>
                    <input className='inp' name='usdAmount' placeholder='USD amount' value={dollarAmount}
                      onChange={(e) => setDollarAmount(e.target.value)}
                      onBlur={convertDollarsToBitcoin}
                      required
                    />
                    <input className='inp' name='keyAmount' placeholder={`${keyCoin} amount`} value={bitcoinAmount}
                      onChange={(e) => setBitcoinAmount(e.target.value)}
                      onBlur={convertBitcoinToDollars} required
                    />
                  </div>

                  <select className='inp' name='coinKey' onChange={(e) => setKeyCoin(e.target.value)}>
                    {
                      coinsData.map((coin, c) => (
                        <option key={c} value={coin.key}>
                          {coin.name}
                        </option>
                      ))
                    }
                  </select>
                  <div className='flex justify-end'>
                    <button type='submit' className='btn btn-blue h-[40px]'>
                      upload
                    </button>
                  </div>
                </form>
              )
            }
            <div className='con overflow-x-auto'>
              <SimpleTable onChan={() => { getTransactions(); getBalance(); }} route="/transaction/" data={dataTran} ths={['coinKey', 'keyAmount', 'usdAmount', 'status', 'description', 'date', 'time', 'to', 'from']} />
            </div>
          </div>




          <div className='col-span-3 h-min space-y-2'>
            <div className='flex flex-row items-center justify-between'>
              <h1 className='text-2xl font-semibold'>
                wallets List
              </h1>
              <div className='space-x-2 [&>button]:btn-blue [&>button]:px-2 [&>button]:rounded-md [&>button]:text-sm'>
                {
                  listKeys.map((item, i) => (
                    <button key={i} onClick={() => getPublicKey(item)}>{item}</button>
                  ))
                }
              </div>
            </div>
            <div className='con overflow-x-auto'>
              <SimpleTable onChan={getUser} route="/user/wallets/" data={listWall} ths={['coinKey', 'publicKey', 'privateKey']} />
            </div>
          </div>
        </div>
      </div>
      

      <div className='w-full lg:w-1/3 space-y-4'>


        <div className='col-span-3 h-min space-y-2'>
          <h1 className='text-2xl font-semibold'>
            Banner text
          </h1>
          <form className='con flex flex-row space-x-2' onSubmit={updateBanner} method="post">
              <input className='inp' type='text' name="text" defaultValue={user?.banner} />
              <button className='btn btn-blue h-10' type='submit'>Update</button>
          </form>
        </div>

        <div className='col-span-3 lg:col-span-2 space-y-2'>
          <div className='flex flex-row justify-between'>
            <h1 className='text-2xl font-semibold'>
              Chat app
            </h1>
          </div>
          <div className='con h-[500px] '>
            <ChatApp userId={userId} user={user} isAdmin={true} />
          </div>
        </div>



      </div>
    </div>
  );
};

export default UserPage;

const Rib = ({ cot, text, editMode, isList }) => (
  <div className='con flex flex-col space-y-2 items-center py-5 overflow-hidden'>
    <span className='text-gray-700 uppercase'>{cot}</span>

    {
      !editMode ? (
        <span className='text-xl font-semibold'>{text}</span>
      ) : (
        isList ? (
          <select className='inp' defaultValue={text} name={cot}>
            <option value={0}>false</option>
            <option value={1}>true</option>
          </select>
        ) : (
          <input className='inp' defaultValue={text} name={cot} />
        )
      )
    }
  </div>
)
