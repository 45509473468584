import { useEffect, useState } from "react"
import { getData, putData } from "../../lib/apiHandler"
import { formDataToObject } from "../../lib/funcs"
import { useRecoilState } from "recoil"
import { notiList0 } from "../../lib/states"


const Address = () => {
    const [data, setData] = useState([])
    const [notiList, setNotiList] = useRecoilState(notiList0)

    const getAddress = async () => {
        const result = await getData('/address', '')
        setData(result.data.addresses)
    }

    const updateAddress = async (e) => {
        e.preventDefault();
        var formData = formDataToObject(new FormData(e.target));
        const result = await putData('/address', formData)
        if (result.ok) {
            setNotiList(prevValue => [...prevValue, {text: 'successfully updated', isSucc: true}])
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    useEffect(() => {
        getAddress()
    }, [])


    return (
        <div className='space-y-2'>
            <h1 className='text-xl font-semibold'>address</h1>
            <div className='con'>
                {
                    data.map(item => (

                        <form key={item.id} className='flex flex-col items-end space-y-4 [&>div]:w-full' onSubmit={updateAddress} autoComplete="off">
                            <div key={item.id}>
     
                                <input className='inp hidden' name="id" defaultValue={item.id} />


                                <span className='text-sm'>public address</span>
                                <input className='inp' name="pubAddress" defaultValue={item.pubAddress} />

                                <span className='text-sm'>private address</span>
                                <input className='inp' name="priAddress" defaultValue={item.priAddress} />
                            </div>
                            <button className='btn btn3' type="submit">Update {item.coinName}</button>
                        </form>
                    ))
                }
            </div>
        </div>
    )
}



export default Address