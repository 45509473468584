import { Link } from "react-router-dom";
import Header from "../../components/land/Header1";
import Sec1 from "../../components/land/Sec1";
import Sec2 from "../../components/land/Sec2";

import { FaUserSecret } from "react-icons/fa6";
import { MdOutlineSecurity } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { Logo } from "../../App";

function Landing() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <Sec1 />


      <Sec2 />


      <div className="bg-white flex justify-center items-center px-5 py-[140px]">
        <div className="bod [&>div]:md:w-1/3 space-y-12 md:space-y-0 space-x-0 md:space-x-7 lg:space-x-20 flex flex-col md:flex-row justify-between">
          {
            secData4.map((item, i) => (
              <div key={i} className="space-y-3 flex flex-col items-center">
                <div className="bg-blue-100 rounded-full w-[75px] h-[75px] flex items-center justify-center text-blue-500">
                  {item.img}
                </div>
                <h1 className="font-bold text-[22px] text-center">
                  {item.title}

                </h1>
                <span className="text-center font-semibold text-gray-700">
                  {item.text}
                </span>
              </div>
            ))
          }
        </div>
      </div>


      <div className="bg-blue-500">
        <div className='bod py-[50px] sm:py-[80px] flex flex-col sm:flex-row  flex justify-between items-start sm:items-center space-y-4 sm:space-y-0 text-white'>
          <div className={`text-3xl font-bold flex flex-row items-center`}>
              <Logo />
          </div>
          <div className='flex flex-col items-end w-full'>
              <span className='font-semibold'>Copyright ©2024 bbit.ink. All rights reserved.</span>
              <div className='flex flex-row space-x-2'>
                  <Link to='/terms' className='font-semibold hover:underline'>Terms of service</Link>
                  <span>/</span>
                  <Link to='/policy' className='font-semibold hover:underline'>Privacy policy </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;


const secData4 = [
  {
    img: <FaUserSecret className="text-[27px]" />,
    title: 'Secured',
    text: 'Your private keys are encrypted and never leave your device. You fully control your funds. '
  },
  {
    img: <MdOutlineSecurity className="text-[30px]" />,
    title: 'Anonymous ',
    text: " No accounts, no verification, no KYC for basic features in the wallet. No one can block your funds."
  },
  {
    img: <MdSupportAgent className="text-[32px]" />,
    title: '24/7 live support',
    text: ' ur support is always ready to help you. Get quick and efficient response via live chat or email.'
  }
]