

import {ReactComponent as AD} from '../lib/flags/ad.svg'
import {ReactComponent as AE} from '../lib/flags/ae.svg'
import {ReactComponent as AF} from '../lib/flags/af.svg'
import {ReactComponent as AG} from '../lib/flags/ag.svg'
import {ReactComponent as AI} from '../lib/flags/ai.svg'
import {ReactComponent as AL} from '../lib/flags/al.svg'
import {ReactComponent as AM} from '../lib/flags/am.svg'
import {ReactComponent as AO} from '../lib/flags/ao.svg'
import {ReactComponent as AQ} from '../lib/flags/aq.svg'
import {ReactComponent as AR} from '../lib/flags/ar.svg'
import {ReactComponent as AS} from '../lib/flags/as.svg'
import {ReactComponent as AT} from '../lib/flags/at.svg'
import {ReactComponent as AU} from '../lib/flags/au.svg'
import {ReactComponent as AW} from '../lib/flags/aw.svg'
import {ReactComponent as AX} from '../lib/flags/ax.svg'
import {ReactComponent as AZ} from '../lib/flags/az.svg'
import {ReactComponent as BA} from '../lib/flags/ba.svg'
import {ReactComponent as BB} from '../lib/flags/bb.svg'
import {ReactComponent as BD} from '../lib/flags/bd.svg'
import {ReactComponent as BE} from '../lib/flags/be.svg'
import {ReactComponent as BF} from '../lib/flags/bf.svg'
import {ReactComponent as BG} from '../lib/flags/bg.svg'
import {ReactComponent as BH} from '../lib/flags/bh.svg'
import {ReactComponent as BI} from '../lib/flags/bi.svg'
import {ReactComponent as BJ} from '../lib/flags/bj.svg'
import {ReactComponent as BL} from '../lib/flags/bl.svg'
import {ReactComponent as BM} from '../lib/flags/bm.svg'
import {ReactComponent as BN} from '../lib/flags/bn.svg'
import {ReactComponent as BO} from '../lib/flags/bo.svg'
import {ReactComponent as BQ} from '../lib/flags/bq.svg'
import {ReactComponent as BR} from '../lib/flags/br.svg'
import {ReactComponent as BS} from '../lib/flags/bs.svg'
import {ReactComponent as BT} from '../lib/flags/bt.svg'
import {ReactComponent as BV} from '../lib/flags/bv.svg'
import {ReactComponent as BW} from '../lib/flags/bw.svg'
import {ReactComponent as BY} from '../lib/flags/by.svg'
import {ReactComponent as BZ} from '../lib/flags/bz.svg'
import {ReactComponent as CA} from '../lib/flags/ca.svg'
import {ReactComponent as CC} from '../lib/flags/cc.svg'
import {ReactComponent as CD} from '../lib/flags/cd.svg'
import {ReactComponent as CF} from '../lib/flags/cf.svg'
import {ReactComponent as CG} from '../lib/flags/cg.svg'
import {ReactComponent as CH} from '../lib/flags/ch.svg'
import {ReactComponent as CI} from '../lib/flags/ci.svg'
import {ReactComponent as CK} from '../lib/flags/ck.svg'
import {ReactComponent as CL} from '../lib/flags/cl.svg'
import {ReactComponent as CM} from '../lib/flags/cm.svg'
import {ReactComponent as CN} from '../lib/flags/cn.svg'
import {ReactComponent as CO} from '../lib/flags/co.svg'
import {ReactComponent as CR} from '../lib/flags/cr.svg'
// import {ReactComponent as CU} from '../lib/flags/cu.svg'
import {ReactComponent as CV} from '../lib/flags/cv.svg'
import {ReactComponent as CW} from '../lib/flags/cw.svg'
import {ReactComponent as CX} from '../lib/flags/cx.svg'
import {ReactComponent as CY} from '../lib/flags/cy.svg'
import {ReactComponent as CZ} from '../lib/flags/cz.svg'
import {ReactComponent as DE} from '../lib/flags/de.svg'
import {ReactComponent as DJ} from '../lib/flags/dj.svg'
import {ReactComponent as DK} from '../lib/flags/dk.svg'
import {ReactComponent as DM} from '../lib/flags/dm.svg'
import {ReactComponent as DO} from '../lib/flags/do.svg'
import {ReactComponent as DZ} from '../lib/flags/dz.svg'
import {ReactComponent as EC} from '../lib/flags/ec.svg'
import {ReactComponent as EE} from '../lib/flags/ee.svg'
import {ReactComponent as EG} from '../lib/flags/eg.svg'
import {ReactComponent as EH} from '../lib/flags/eh.svg'
import {ReactComponent as ER} from '../lib/flags/er.svg'
import {ReactComponent as ES} from '../lib/flags/es.svg'
import {ReactComponent as ET} from '../lib/flags/et.svg'
import {ReactComponent as EU} from '../lib/flags/eu.svg'
import {ReactComponent as FI} from '../lib/flags/fi.svg'
import {ReactComponent as FJ} from '../lib/flags/fj.svg'
import {ReactComponent as FK} from '../lib/flags/fk.svg'
import {ReactComponent as FM} from '../lib/flags/fm.svg'
import {ReactComponent as FO} from '../lib/flags/fo.svg'
import {ReactComponent as FR} from '../lib/flags/fr.svg'
import {ReactComponent as GA} from '../lib/flags/ga.svg'
import {ReactComponent as GB} from '../lib/flags/gb-eng.svg'
import {ReactComponent as GD} from '../lib/flags/gd.svg'
import {ReactComponent as GE} from '../lib/flags/ge.svg'
import {ReactComponent as GF} from '../lib/flags/gf.svg'
import {ReactComponent as GG} from '../lib/flags/gg.svg'
import {ReactComponent as GH} from '../lib/flags/gh.svg'
import {ReactComponent as GI} from '../lib/flags/gi.svg'
import {ReactComponent as GL} from '../lib/flags/gl.svg'
import {ReactComponent as GM} from '../lib/flags/gm.svg'
import {ReactComponent as GN} from '../lib/flags/gn.svg'
import {ReactComponent as GP} from '../lib/flags/gp.svg'
import {ReactComponent as GQ} from '../lib/flags/gq.svg'
import {ReactComponent as GR} from '../lib/flags/gr.svg'
import {ReactComponent as GS} from '../lib/flags/gs.svg'
import {ReactComponent as GT} from '../lib/flags/gt.svg'
import {ReactComponent as GU} from '../lib/flags/gu.svg'
import {ReactComponent as GW} from '../lib/flags/gw.svg'
import {ReactComponent as GY} from '../lib/flags/gy.svg'
import {ReactComponent as HK} from '../lib/flags/hk.svg'
import {ReactComponent as HM} from '../lib/flags/hm.svg'
import {ReactComponent as HN} from '../lib/flags/hn.svg'
import {ReactComponent as HR} from '../lib/flags/hr.svg'
import {ReactComponent as HT} from '../lib/flags/ht.svg'
import {ReactComponent as HU} from '../lib/flags/hu.svg'
import {ReactComponent as ID} from '../lib/flags/id.svg'
import {ReactComponent as IE} from '../lib/flags/ie.svg'
import {ReactComponent as IL} from '../lib/flags/il.svg'
import {ReactComponent as IM} from '../lib/flags/im.svg'
import {ReactComponent as IN} from '../lib/flags/in.svg'
import {ReactComponent as IO} from '../lib/flags/io.svg'
import {ReactComponent as IQ} from '../lib/flags/iq.svg'
import {ReactComponent as IR} from '../lib/flags/ir.svg'
import {ReactComponent as IS} from '../lib/flags/is.svg'
import {ReactComponent as IT} from '../lib/flags/it.svg'
import {ReactComponent as JE} from '../lib/flags/je.svg'
import {ReactComponent as JM} from '../lib/flags/jm.svg'
import {ReactComponent as JO} from '../lib/flags/jo.svg'
import {ReactComponent as JP} from '../lib/flags/jp.svg'
import {ReactComponent as KE} from '../lib/flags/ke.svg'
import {ReactComponent as KG} from '../lib/flags/kg.svg'
import {ReactComponent as KH} from '../lib/flags/kh.svg'
import {ReactComponent as KI} from '../lib/flags/ki.svg'
import {ReactComponent as KM} from '../lib/flags/km.svg'
import {ReactComponent as KN} from '../lib/flags/kn.svg'
import {ReactComponent as KP} from '../lib/flags/kp.svg'
import {ReactComponent as KR} from '../lib/flags/kr.svg'
import {ReactComponent as KW} from '../lib/flags/kw.svg'
import {ReactComponent as KY} from '../lib/flags/ky.svg'
import {ReactComponent as KZ} from '../lib/flags/kz.svg'
import {ReactComponent as LA} from '../lib/flags/la.svg'
import {ReactComponent as LB} from '../lib/flags/lb.svg'
import {ReactComponent as LC} from '../lib/flags/lc.svg'
// import {ReactComponent as LI} from '../lib/flags/li.svg'
import {ReactComponent as LK} from '../lib/flags/lk.svg'
import {ReactComponent as LR} from '../lib/flags/lr.svg'
import {ReactComponent as LS} from '../lib/flags/ls.svg'
import {ReactComponent as LT} from '../lib/flags/lt.svg'
import {ReactComponent as LU} from '../lib/flags/lu.svg'
import {ReactComponent as LV} from '../lib/flags/lv.svg'
import {ReactComponent as LY} from '../lib/flags/ly.svg'
import {ReactComponent as MA} from '../lib/flags/ma.svg'
import {ReactComponent as MC} from '../lib/flags/mc.svg'
import {ReactComponent as MD} from '../lib/flags/md.svg'
import {ReactComponent as ME} from '../lib/flags/me.svg'
import {ReactComponent as MF} from '../lib/flags/mf.svg'
import {ReactComponent as MG} from '../lib/flags/mg.svg'
import {ReactComponent as MH} from '../lib/flags/mh.svg'
import {ReactComponent as MK} from '../lib/flags/mk.svg'
import {ReactComponent as ML} from '../lib/flags/ml.svg'
import {ReactComponent as MM} from '../lib/flags/mm.svg'
import {ReactComponent as MN} from '../lib/flags/mn.svg'
import {ReactComponent as MO} from '../lib/flags/mo.svg'
import {ReactComponent as MP} from '../lib/flags/mp.svg'
import {ReactComponent as MQ} from '../lib/flags/mq.svg'
import {ReactComponent as MR} from '../lib/flags/mr.svg'
import {ReactComponent as MS} from '../lib/flags/ms.svg'
import {ReactComponent as MT} from '../lib/flags/mt.svg'
import {ReactComponent as MU} from '../lib/flags/mu.svg'
import {ReactComponent as MV} from '../lib/flags/mv.svg'
import {ReactComponent as MW} from '../lib/flags/mw.svg'
import {ReactComponent as MX} from '../lib/flags/mx.svg'
import {ReactComponent as MY} from '../lib/flags/my.svg'
import {ReactComponent as MZ} from '../lib/flags/mz.svg'
import {ReactComponent as NA} from '../lib/flags/na.svg'
import {ReactComponent as NC} from '../lib/flags/nc.svg'
import {ReactComponent as NE} from '../lib/flags/ne.svg'
import {ReactComponent as NF} from '../lib/flags/nf.svg'
import {ReactComponent as NG} from '../lib/flags/ng.svg'
import {ReactComponent as NI} from '../lib/flags/ni.svg'
import {ReactComponent as NL} from '../lib/flags/nl.svg'
import {ReactComponent as NO} from '../lib/flags/no.svg'
import {ReactComponent as NP} from '../lib/flags/np.svg'
import {ReactComponent as NR} from '../lib/flags/nr.svg'
import {ReactComponent as NU} from '../lib/flags/nu.svg'
import {ReactComponent as NZ} from '../lib/flags/nz.svg'
import {ReactComponent as OM} from '../lib/flags/om.svg'
import {ReactComponent as PA} from '../lib/flags/pa.svg'
import {ReactComponent as PE} from '../lib/flags/pe.svg'
import {ReactComponent as PF} from '../lib/flags/pf.svg'
import {ReactComponent as PG} from '../lib/flags/pg.svg'
import {ReactComponent as PH} from '../lib/flags/ph.svg'
import {ReactComponent as PK} from '../lib/flags/pk.svg'
import {ReactComponent as PL} from '../lib/flags/pl.svg'
import {ReactComponent as PM} from '../lib/flags/pm.svg'
import {ReactComponent as PN} from '../lib/flags/pn.svg'
import {ReactComponent as PR} from '../lib/flags/pr.svg'
import {ReactComponent as PS} from '../lib/flags/ps.svg'
import {ReactComponent as PT} from '../lib/flags/pt.svg'
import {ReactComponent as PW} from '../lib/flags/pw.svg'
import {ReactComponent as PY} from '../lib/flags/py.svg'
import {ReactComponent as QA} from '../lib/flags/qa.svg'
import {ReactComponent as RE} from '../lib/flags/re.svg'
import {ReactComponent as RO} from '../lib/flags/ro.svg'
import {ReactComponent as RS} from '../lib/flags/rs.svg'
import {ReactComponent as RU} from '../lib/flags/ru.svg'
import {ReactComponent as RW} from '../lib/flags/rw.svg'
import {ReactComponent as SA} from '../lib/flags/sa.svg'
import {ReactComponent as SB} from '../lib/flags/sb.svg'
import {ReactComponent as SC} from '../lib/flags/sc.svg'
import {ReactComponent as SD} from '../lib/flags/sd.svg'
import {ReactComponent as SE} from '../lib/flags/se.svg'
import {ReactComponent as SG} from '../lib/flags/sg.svg'
import {ReactComponent as SH} from '../lib/flags/sh.svg'
import {ReactComponent as SI} from '../lib/flags/si.svg'
import {ReactComponent as SJ} from '../lib/flags/sj.svg'
import {ReactComponent as SK} from '../lib/flags/sk.svg'
import {ReactComponent as SL} from '../lib/flags/sl.svg'
import {ReactComponent as SM} from '../lib/flags/sm.svg'
import {ReactComponent as SN} from '../lib/flags/sn.svg'
import {ReactComponent as SO} from '../lib/flags/so.svg'
import {ReactComponent as SR} from '../lib/flags/sr.svg'
import {ReactComponent as SS} from '../lib/flags/ss.svg'
import {ReactComponent as ST} from '../lib/flags/st.svg'
import {ReactComponent as SV} from '../lib/flags/sv.svg'
import {ReactComponent as SX} from '../lib/flags/sx.svg'
import {ReactComponent as SY} from '../lib/flags/sy.svg'
import {ReactComponent as SZ} from '../lib/flags/sz.svg'
import {ReactComponent as TC} from '../lib/flags/tc.svg'
import {ReactComponent as TD} from '../lib/flags/td.svg'
import {ReactComponent as TF} from '../lib/flags/tf.svg'
import {ReactComponent as TG} from '../lib/flags/tg.svg'
import {ReactComponent as TH} from '../lib/flags/th.svg'
import {ReactComponent as TJ} from '../lib/flags/tj.svg'
import {ReactComponent as TK} from '../lib/flags/tk.svg'
import {ReactComponent as TL} from '../lib/flags/tl.svg'
import {ReactComponent as TM} from '../lib/flags/tm.svg'
import {ReactComponent as TN} from '../lib/flags/tn.svg'
import {ReactComponent as TO} from '../lib/flags/to.svg'
import {ReactComponent as TR} from '../lib/flags/tr.svg'
import {ReactComponent as TT} from '../lib/flags/tt.svg'
import {ReactComponent as TV} from '../lib/flags/tv.svg'
import {ReactComponent as TW} from '../lib/flags/tw.svg'
import {ReactComponent as TZ} from '../lib/flags/tz.svg'
import {ReactComponent as UA} from '../lib/flags/ua.svg'
import {ReactComponent as UG} from '../lib/flags/ug.svg'
import {ReactComponent as UM} from '../lib/flags/um.svg'
import {ReactComponent as US} from '../lib/flags/us.svg'
import {ReactComponent as UY} from '../lib/flags/uy.svg'
import {ReactComponent as UZ} from '../lib/flags/uz.svg'
import {ReactComponent as VA} from '../lib/flags/va.svg'
import {ReactComponent as VC} from '../lib/flags/vc.svg'
import {ReactComponent as VE} from '../lib/flags/ve.svg'
import {ReactComponent as VG} from '../lib/flags/vg.svg'
import {ReactComponent as VI} from '../lib/flags/vi.svg'
import {ReactComponent as VN} from '../lib/flags/vn.svg'
import {ReactComponent as VU} from '../lib/flags/vu.svg'
import {ReactComponent as WF} from '../lib/flags/wf.svg'
import {ReactComponent as WS} from '../lib/flags/ws.svg'
import {ReactComponent as XK} from '../lib/flags/xk.svg'
import {ReactComponent as YE} from '../lib/flags/ye.svg'
import {ReactComponent as YT} from '../lib/flags/yt.svg'
import {ReactComponent as ZA} from '../lib/flags/za.svg'
import {ReactComponent as ZM} from '../lib/flags/zm.svg'
import {ReactComponent as ZW} from '../lib/flags/zw.svg'





export default [
    {
        "name": "Afghanistan",
        "code": "93",
        "iso2": <AF/>
    },
    {
        "name": "Albania",
        "code": "355",
        "iso2": <AL/>
    },
    {
        "name": "Algeria",
        "code": "213",
        "iso2": <DZ/>
    },
    {
        "name": "American Samoa",
        "code": "1684",
        "iso2": <AS/>
    },
    {
        "name": "Andorra",
        "code": "376",
        "iso2": <AD/>
    },
    {
        "name": "Angola",
        "code": "244",
        "iso2": <AO/>
    },
    {
        "name": "Anguilla",
        "code": "1264",
        "iso2": <AI/>
    },
    {
        "name": "Antarctica",
        "code": "672",
        "iso2": <AQ/>
    },
    {
        "name": "Antigua and Barbuda",
        "code": "1268",
        "iso2": <AG/>
    },
    {
        "name": "Argentina",
        "code": "54",
        "iso2": <AR/>
    },
    {
        "name": "Armenia",
        "code": "374",
        "iso2": <AM/>
    },
    {
        "name": "Aruba",
        "code": "297",
        "iso2": <AW/>
    },
    {
        "name": "Australia",
        "code": "61",
        "iso2": <AU/>
    },
    {
        "name": "Austria",
        "code": "43",
        "iso2": <AT/>
    },
    {
        "name": "Azerbaijan",
        "code": "994",
        "iso2": <AZ/>
    },
    {
        "name": "Bahamas",
        "code": "1242",
        "iso2": <BS/>
    },
    {
        "name": "Bahrain",
        "code": "973",
        "iso2": <BH/>
    },
    {
        "name": "Bangladesh",
        "code": "880",
        "iso2": <BD/>
    },
    {
        "name": "Barbados",
        "code": "1246",
        "iso2": <BB/>
    },
    {
        "name": "Belarus",
        "code": "375",
        "iso2": <BY/>
    },
    {
        "name": "Belgium",
        "code": "32",
        "iso2": <BE/>
    },
    {
        "name": "Belize",
        "code": "501",
        "iso2": <BZ/>
    },
    {
        "name": "Benin",
        "code": "229",
        "iso2": <BJ/>
    },
    {
        "name": "Bermuda",
        "code": "1441",
        "iso2": <BM/>
    },
    {
        "name": "Bhutan",
        "code": "975",
        "iso2": <BT/>
    },
    {
        "name": "Bolivia",
        "code": "591",
        "iso2": <BO/>
    },
    {
        "name": "Bosnia and Herzegovina",
        "code": "387",
        "iso2": <BA/>
    },
    {
        "name": "Botswana",
        "code": "267",
        "iso2": <BW/>
    },
    {
        "name": "Brazil",
        "code": "55",
        "iso2": <BR/>
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "246",
        "iso2": <IO/>
    },
    {
        "name": "British Virgin Islands",
        "code": "1284",
        "iso2": <VG/>
    },
    {
        "name": "Brunei",
        "code": "673",
        "iso2": <BN/>
    },
    {
        "name": "Bulgaria",
        "code": "359",
        "iso2": <BG/>
    },
    {
        "name": "Burkina Faso",
        "code": "226",
        "iso2": <BF/>
    },
    {
        "name": "Burundi",
        "code": "257",
        "iso2": <BI/>
    },
    {
        "name": "Cambodia",
        "code": "855",
        "iso2": <KH/>
    },
    {
        "name": "Cameroon",
        "code": "237",
        "iso2": <CM/>
    },
    {
        "name": "Canada",
        "code": "1",
        "iso2": <CA/>
    },
    {
        "name": "Cape Verde",
        "code": "238",
        "iso2": <CV/>
    },
    {
        "name": "Cayman Islands",
        "code": "1345",
        "iso2": <KY/>
    },
    {
        "name": "Central African Republic",
        "code": "236",
        "iso2": <CF/>
    },
    {
        "name": "Chad",
        "code": "235",
        "iso2": <TD/>
    },
    {
        "name": "Chile",
        "code": "56",
        "iso2": <CL/>
    },
    {
        "name": "China",
        "code": "86",
        "iso2": <CN/>
    },
    {
        "name": "Christmas Island",
        "code": "61",
        "iso2": <CX/>
    },
    {
        "name": "Cocos Islands",
        "code": "61",
        "iso2": <CC/>
    },
    {
        "name": "Colombia",
        "code": "57",
        "iso2": <CO/>
    },
    {
        "name": "Comoros",
        "code": "269",
        "iso2": <KM/>
    },
    {
        "name": "Cook Islands",
        "code": "682",
        "iso2": <CK/>
    },
    {
        "name": "Costa Rica",
        "code": "506",
        "iso2": <CR/>
    },
    {
        "name": "Croatia",
        "code": "385",
        "iso2": <HR/>
    },
    // {
    //     "name": "Cuba",
    //     "code": "53",
    //     "iso2": <CU/>
    // },
    {
        "name": "Curacao",
        "code": "599",
        "iso2": <CW/>
    },
    {
        "name": "Cyprus",
        "code": "357",
        "iso2": <CY/>
    },
    {
        "name": "Czech Republic",
        "code": "420",
        "iso2": <CZ/>
    },
    {
        "name": "Democratic Republic of the Congo",
        "code": "243",
        "iso2": <CD/>
    },
    {
        "name": "Denmark",
        "code": "45",
        "iso2": <DK/>
    },
    {
        "name": "Djibouti",
        "code": "253",
        "iso2": <DJ/>
    },
    {
        "name": "Dominica",
        "code": "1767",
        "iso2": <DM/>
    },
    {
        "name": "Dominican Republic",
        "code": "1809",
        "iso2": <DO/>
    },
    {
        "name": "East Timor",
        "code": "670",
        "iso2": <TL/>
    },
    {
        "name": "Ecuador",
        "code": "593",
        "iso2": <EC/>
    },
    {
        "name": "Egypt",
        "code": "20",
        "iso2": <EG/>
    },
    {
        "name": "El Salvador",
        "code": "503",
        "iso2": <SV/>
    },
    {
        "name": "Equatorial Guinea",
        "code": "240",
        "iso2": <GQ/>
    },
    {
        "name": "Eritrea",
        "code": "291",
        "iso2": <ER/>
    },
    {
        "name": "Estonia",
        "code": "372",
        "iso2": <EE/>
    },
    {
        "name": "Ethiopia",
        "code": "251",
        "iso2": <ET/>
    },
    {
        "name": "Falkland Islands",
        "code": "500",
        "iso2": <FK/>
    },
    {
        "name": "Faroe Islands",
        "code": "298",
        "iso2": <FO/>
    },
    {
        "name": "Fiji",
        "code": "679",
        "iso2": <FJ/>
    },
    {
        "name": "Finland",
        "code": "358",
        "iso2": <FI/>
    },
    {
        "name": "France",
        "code": "33",
        "iso2": <FR/>
    },
    {
        "name": "French Polynesia",
        "code": "689",
        "iso2": <PF/>
    },
    {
        "name": "Gabon",
        "code": "241",
        "iso2": <GA/>
    },
    {
        "name": "Gambia",
        "code": "220",
        "iso2": <GM/>
    },
    {
        "name": "Georgia",
        "code": "995",
        "iso2": <GE/>
    },
    {
        "name": "Germany",
        "code": "49",
        "iso2": <DE/>
    },
    {
        "name": "Ghana",
        "code": "233",
        "iso2": <GH/>
    },
    {
        "name": "Gibraltar",
        "code": "350",
        "iso2": <GI/>
    },
    {
        "name": "Greece",
        "code": "30",
        "iso2": <GR/>
    },
    {
        "name": "Greenland",
        "code": "299",
        "iso2": <GL/>
    },
    {
        "name": "Grenada",
        "code": "1473",
        "iso2": <GD/>
    },
    {
        "name": "Guam",
        "code": "1671",
        "iso2": <GU/>
    },
    {
        "name": "Guatemala",
        "code": "502",
        "iso2": <GT/>
    },
    {
        "name": "Guernsey",
        "code": "441481",
        "iso2": <GG/>
    },
    {
        "name": "Guinea",
        "code": "224",
        "iso2": <GN/>
    },
    {
        "name": "Guinea-Bissau",
        "code": "245",
        "iso2": <GW/>
    },
    {
        "name": "Guyana",
        "code": "592",
        "iso2": <GY/>
    },
    {
        "name": "Haiti",
        "code": "509",
        "iso2": <HT/>
    },
    {
        "name": "Honduras",
        "code": "504",
        "iso2": <HN/>
    },
    {
        "name": "Hong Kong",
        "code": "852",
        "iso2": <HK/>
    },
    {
        "name": "Hungary",
        "code": "36",
        "iso2": <HU/>
    },
    {
        "name": "Iceland",
        "code": "354",
        "iso2": <IS/>
    },
    {
        "name": "India",
        "code": "91",
        "iso2": <IN/>
    },
    {
        "name": "Indonesia",
        "code": "62",
        "iso2": <ID/>
    },
    {
        "name": "Iran",
        "code": "98",
        "iso2": <IR/>
    },
    {
        "name": "Iraq",
        "code": "964",
        "iso2": <IQ/>
    },
    {
        "name": "Ireland",
        "code": "353",
        "iso2": <IE/>
    },
    {
        "name": "Isle of Man",
        "code": "441624",
        "iso2": <IM/>
    },
    {
        "name": "Israel",
        "code": "972",
        "iso2": <IL/>
    },
    {
        "name": "Italy",
        "code": "39",
        "iso2": <IT/>
    },
    {
        "name": "Ivory Coast",
        "code": "225",
        "iso2": <CI/>
    },
    {
        "name": "Jamaica",
        "code": "1876",
        "iso2": <JM/>
    },
    {
        "name": "Japan",
        "code": "81",
        "iso2": <JP/>
    },
    {
        "name": "Jersey",
        "code": "441534",
        "iso2": <JE/>
    },
    {
        "name": "Jordan",
        "code": "962",
        "iso2": <JO/>
    },
    {
        "name": "Kazakhstan",
        "code": "7",
        "iso2": <KZ/>
    },
    {
        "name": "Kenya",
        "code": "254",
        "iso2": <KE/>
    },
    {
        "name": "Kiribati",
        "code": "686",
        "iso2": <KI/>
    },
    {
        "name": "Kosovo",
        "code": "383",
        "iso2": <XK/>
    },
    {
        "name": "Kuwait",
        "code": "965",
        "iso2": <KW/>
    },
    {
        "name": "Kyrgyzstan",
        "code": "996",
        "iso2": <KG/>
    },
    {
        "name": "Laos",
        "code": "856",
        "iso2": <LA/>
    },
    {
        "name": "Latvia",
        "code": "371",
        "iso2": <LV/>
    },
    {
        "name": "Lebanon",
        "code": "961",
        "iso2": <LB/>
    },
    {
        "name": "Lesotho",
        "code": "266",
        "iso2": <LS/>
    },
    {
        "name": "Liberia",
        "code": "231",
        "iso2": <LR/>
    },
    {
        "name": "Libya",
        "code": "218",
        "iso2": <LY/>
    },
    // {
    //     "name": "Liechtenstein",
    //     "code": "423",
    //     "iso2": <LI/>
    // },
    {
        "name": "Lithuania",
        "code": "370",
        "iso2": <LT/>
    },
    {
        "name": "Luxembourg",
        "code": "352",
        "iso2": <LU/>
    },
    {
        "name": "Macau",
        "code": "853",
        "iso2": <MO/>
    },
    {
        "name": "Macedonia",
        "code": "389",
        "iso2": <MK/>
    },
    {
        "name": "Madagascar",
        "code": "261",
        "iso2": <MG/>
    },
    {
        "name": "Malawi",
        "code": "265",
        "iso2": <MW/>
    },
    {
        "name": "Malaysia",
        "code": "60",
        "iso2": <MY/>
    },
    {
        "name": "Maldives",
        "code": "960",
        "iso2": <MV/>
    },
    {
        "name": "Mali",
        "code": "223",
        "iso2": <ML/>
    },
    {
        "name": "Malta",
        "code": "356",
        "iso2": <MT/>
    },
    {
        "name": "Marshall Islands",
        "code": "692",
        "iso2": <MH/>
    },
    {
        "name": "Mauritania",
        "code": "222",
        "iso2": <MR/>
    },
    {
        "name": "Mauritius",
        "code": "230",
        "iso2": <MU/>
    },
    {
        "name": "Mayotte",
        "code": "262",
        "iso2": <YT/>
    },
    {
        "name": "Mexico",
        "code": "52",
        "iso2": <MX/>
    },
    {
        "name": "Micronesia",
        "code": "691",
        "iso2": <FM/>
    },
    {
        "name": "Moldova",
        "code": "373",
        "iso2": <MD/>
    },
    {
        "name": "Monaco",
        "code": "377",
        "iso2": <MC/>
    },
    {
        "name": "Mongolia",
        "code": "976",
        "iso2": <MN/>
    },
    {
        "name": "Montenegro",
        "code": "382",
        "iso2": <ME/>
    },
    {
        "name": "Montserrat",
        "code": "1664",
        "iso2": <MS/>
    },
    {
        "name": "Morocco",
        "code": "212",
        "iso2": <MA/>
    },
    {
        "name": "Mozambique",
        "code": "258",
        "iso2": <MZ/>
    },
    {
        "name": "Myanmar",
        "code": "95",
        "iso2": <MM/>
    },
    {
        "name": "Namibia",
        "code": "264",
        "iso2": <NA/>
    },
    {
        "name": "Nauru",
        "code": "674",
        "iso2": <NR/>
    },
    {
        "name": "Nepal",
        "code": "977",
        "iso2": <NP/>
    },
    {
        "name": "Netherlands",
        "code": "31",
        "iso2": <NL/>
    },
    // {
    //     "name": "Netherlands Antilles",
    //     "code": "599",
    //     "iso2": <AN/>
    // },
    {
        "name": "New Caledonia",
        "code": "687",
        "iso2": <NC/>
    },
    {
        "name": "New Zealand",
        "code": "64",
        "iso2": <NZ/>
    },
    {
        "name": "Nicaragua",
        "code": "505",
        "iso2": <NI/>
    },
    {
        "name": "Niger",
        "code": "227",
        "iso2": <NE/>
    },
    {
        "name": "Nigeria",
        "code": "234",
        "iso2": <NG/>
    },
    {
        "name": "Niue",
        "code": "683",
        "iso2": <NU/>
    },
    {
        "name": "North Korea",
        "code": "850",
        "iso2": <KP/>
    },
    {
        "name": "Northern Mariana Islands",
        "code": "1670",
        "iso2": <MP/>
    },
    {
        "name": "Norway",
        "code": "47",
        "iso2": <NO/>
    },
    {
        "name": "Oman",
        "code": "968",
        "iso2": <OM/>
    },
    {
        "name": "Pakistan",
        "code": "92",
        "iso2": <PK/>
    },
    {
        "name": "Palau",
        "code": "680",
        "iso2": <PW/>
    },
    {
        "name": "Palestine",
        "code": "970",
        "iso2": <PS/>
    },
    {
        "name": "Panama",
        "code": "507",
        "iso2": <PA/>
    },
    {
        "name": "Papua New Guinea",
        "code": "675",
        "iso2": <PG/>
    },
    {
        "name": "Paraguay",
        "code": "595",
        "iso2": <PY/>
    },
    {
        "name": "Peru",
        "code": "51",
        "iso2": <PE/>
    },
    {
        "name": "Philippines",
        "code": "63",
        "iso2": <PH/>
    },
    {
        "name": "Pitcairn",
        "code": "64",
        "iso2": <PN/>
    },
    {
        "name": "Poland",
        "code": "48",
        "iso2": <PL/>
    },
    {
        "name": "Portugal",
        "code": "351",
        "iso2": <PT/>
    },
    {
        "name": "Puerto Rico",
        "code": "1787",
        "iso2": <PR/>
    },
    {
        "name": "Qatar",
        "code": "974",
        "iso2": <QA/>
    },
    {
        "name": "Republic of the Congo",
        "code": "242",
        "iso2": <CG/>
    },
    {
        "name": "Reunion",
        "code": "262",
        "iso2": <RE/>
    },
    {
        "name": "Romania",
        "code": "40",
        "iso2": <RO/>
    },
    {
        "name": "Russia",
        "code": "7",
        "iso2": <RU/>
    },
    {
        "name": "Rwanda",
        "code": "250",
        "iso2": <RW/>
    },
    {
        "name": "Saint Barthelemy",
        "code": "590",
        "iso2": <BL/>
    },
    {
        "name": "Saint Helena",
        "code": "290",
        "iso2": <SH/>
    },
    {
        "name": "Saint Kitts and Nevis",
        "code": "1869",
        "iso2": <KN/>
    },
    {
        "name": "Saint Lucia",
        "code": "1758",
        "iso2": <LC/>
    },
    {
        "name": "Saint Martin",
        "code": "590",
        "iso2": <MF/>
    },
    {
        "name": "Saint Pierre and Miquelon",
        "code": "508",
        "iso2": <PM/>
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "code": "1784",
        "iso2": <VC/>
    },
    {
        "name": "Samoa",
        "code": "685",
        "iso2": <WS/>
    },
    {
        "name": "San Marino",
        "code": "378",
        "iso2": <SM/>
    },
    {
        "name": "Sao Tome and Principe",
        "code": "239",
        "iso2": <ST/>
    },
    {
        "name": "Saudi Arabia",
        "code": "966",
        "iso2": <SA/>
    },
    {
        "name": "Senegal",
        "code": "221",
        "iso2": <SN/>
    },
    {
        "name": "Serbia",
        "code": "381",
        "iso2": <RS/>
    },
    {
        "name": "Seychelles",
        "code": "248",
        "iso2": <SC/>
    },
    {
        "name": "Sierra Leone",
        "code": "232",
        "iso2": <SL/>
    },
    {
        "name": "Singapore",
        "code": "65",
        "iso2": <SG/>
    },
    {
        "name": "Sint Maarten",
        "code": "1721",
        "iso2": <SX/>
    },
    {
        "name": "Slovakia",
        "code": "421",
        "iso2": <SK/>
    },
    {
        "name": "Slovenia",
        "code": "386",
        "iso2": <SI/>
    },
    {
        "name": "Solomon Islands",
        "code": "677",
        "iso2": <SB/>
    },
    {
        "name": "Somalia",
        "code": "252",
        "iso2": <SO/>
    },
    {
        "name": "South Africa",
        "code": "27",
        "iso2": <ZA/>
    },
    {
        "name": "South Korea",
        "code": "82",
        "iso2": <KR/>
    },
    {
        "name": "South Sudan",
        "code": "211",
        "iso2": <SS/>
    },
    {
        "name": "Spain",
        "code": "34",
        "iso2": <ES/>
    },
    {
        "name": "Sri Lanka",
        "code": "94",
        "iso2": <LK/>
    },
    {
        "name": "Sudan",
        "code": "249",
        "iso2": <SD/>
    },
    {
        "name": "Suriname",
        "code": "597",
        "iso2": <SR/>
    },
    {
        "name": "Svalbard and Jan Mayen",
        "code": "47",
        "iso2": <SJ/>
    },
    {
        "name": "Swaziland",
        "code": "268",
        "iso2": <SZ/>
    },
    {
        "name": "Sweden",
        "code": "46",
        "iso2": <SE/>
    },
    {
        "name": "Switzerland",
        "code": "41",
        "iso2": <CH/>
    },
    {
        "name": "Syria",
        "code": "963",
        "iso2": <SY/>
    },
    {
        "name": "Taiwan",
        "code": "886",
        "iso2": <TW/>
    },
    {
        "name": "Tajikistan",
        "code": "992",
        "iso2": <TJ/>
    },
    {
        "name": "Tanzania",
        "code": "255",
        "iso2": <TZ/>
    },
    {
        "name": "Thailand",
        "code": "66",
        "iso2": <TH/>
    },
    {
        "name": "Togo",
        "code": "228",
        "iso2": <TG/>
    },
    {
        "name": "Tokelau",
        "code": "690",
        "iso2": <TK/>
    },
    {
        "name": "Tonga",
        "code": "676",
        "iso2": <TO/>
    },
    {
        "name": "Trinidad and Tobago",
        "code": "1868",
        "iso2": <TT/>
    },
    {
        "name": "Tunisia",
        "code": "216",
        "iso2": <TN/>
    },
    {
        "name": "Turkey",
        "code": "90",
        "iso2": <TR/>
    },
    {
        "name": "Turkmenistan",
        "code": "993",
        "iso2": <TM/>
    },
    {
        "name": "Turks and Caicos Islands",
        "code": "1649",
        "iso2": <TC/>
    },
    {
        "name": "Tuvalu",
        "code": "688",
        "iso2": <TV/>
    },
    {
        "name": "U.S. Virgin Islands",
        "code": "1340",
        "iso2": <VI/>
    },
    {
        "name": "Uganda",
        "code": "256",
        "iso2": <UG/>
    },
    {
        "name": "Ukraine",
        "code": "380",
        "iso2": <UA/>
    },
    {
        "name": "United Arab Emirates",
        "code": "971",
        "iso2": <AE/>
    },
    {
        "name": "United Kingdom",
        "code": "44",
        "iso2": <GB/>
    },
    // {
    //     "name": "United States",
    //     "code": "1",
    //     "iso2": <US/>
    // },
    {
        "name": "Uruguay",
        "code": "598",
        "iso2": <UY/>
    },
    {
        "name": "Uzbekistan",
        "code": "998",
        "iso2": <UZ/>
    },
    {
        "name": "Vanuatu",
        "code": "678",
        "iso2": <VU/>
    },
    {
        "name": "Vatican",
        "code": "379",
        "iso2": <VA/>
    },
    {
        "name": "Venezuela",
        "code": "58",
        "iso2": <VE/>
    },
    {
        "name": "Vietnam",
        "code": "84",
        "iso2": <VN/>
    },
    {
        "name": "Wallis and Futuna",
        "code": "681",
        "iso2": <WF/>
    },
    {
        "name": "Western Sahara",
        "code": "212",
        "iso2": <EH/>
    },
    {
        "name": "Yemen",
        "code": "967",
        "iso2": <YE/>
    },
    {
        "name": "Zambia",
        "code": "260",
        "iso2": <ZM/>
    },
    {
        "name": "Zimbabwe",
        "code": "263",
        "iso2": <ZW/>
    }
]