import { useEffect, useState } from "react";
import listPrefix from '../../lib/prefixPhone'


const PrefixInp = () => {
    const [obj, setObj] = useState(listPrefix[37])
    const [phone, setPhone] = useState("+" + obj.code);

    const onChange = (e) => {
        setPhone(prev => {
            var str = '';
            if (e.target.value.substring(0,1) !== "+") {
                str += "+"
            }
            str += e.target.value;
            return str
        })
    }

    const findPrefix = () => {
        for (var i=1; i<4; i++) {
            var find = listPrefix.find(item => item.code === phone.substring(1, i+1))

            if (typeof find !== "undefined") {
                setObj(find)
                break
            } else {
                setObj(
                    {
                        "name": "",
                        "code": "",
                        "iso2": <div className="bg-gray-200 h-[18px] rounded-sm "></div>
                    },
                )
            }

        }
    }

    useEffect(() => {
        findPrefix()
    }, [phone])

    return (
        <div className="flex flex-row">
            <div className="flex items-center h-[42px] px-2 rounded-lg rounded-r-none bg-gray-100 border border-gray-300 [&>*]:w-6 border-r-0">
                {obj.iso2}
            </div>
            <input className="inp rounded-l-none" name="phone" pattern="[0-9+]{9,}" value={phone} onChange={onChange} required />
        </div>
    )
}

export default PrefixInp;

