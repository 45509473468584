

import Landing from "../../pages/lands/Landing1";
import Login from '../../pages/general/logins/Login1';
import Register from '../../pages/general/registers/register1';
import Forgot from '../../pages/general/forgots/Forgot1'

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FaBtc } from "react-icons/fa";


const BrandName = 'BBit';

const changeTitle = () => {
    document.title = "BBit";
}

const MenuTerms = () => {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);

    return (
        <div className="bg-blue-500 h-[100px] px-5 md:px-10 flex flex-row justify-between items-center text-white">
            <div className="cursor-pointer" onClick={() => navigate('/')}>
                <h1 className="text-[30px] font-semibold"><Logo /></h1>
            </div>
            <div className="flex flex-row space-x-2">
                <button className="btn2 btn2-blue" onClick={() => navigate('/login')}>Sign in</button>
                <button className="btn2 btn2-emp" onClick={() => navigate('/register')}>Sign up</button>
            </div>
        </div>
    )
}

const Logo = ({color, color2}) => {
    return (
        <div className={`flex flex-row items-center my-1 font-bold text-[29px] ${color}`}>
            <img className="bg-white rounded-full w-10 mr-1" src="/favicons/fav1.ico" />
            <div className={`flex flex-row items-center space-x-[-2.5px] text-blue-800 ${typeof color2 !== 'undefined' && color2 }`}>
                <span>B</span>
                <FaBtc className="text-[29px] relative top-[1.5px]" />
                <span>IT</span>
            </div>
        </div>
    )
}


export {Landing, Login, Register, MenuTerms, changeTitle, Logo, Forgot, BrandName}