import { useState, useEffect } from 'react';
import Header from '../../components/parts/Header';
import Menu from '../../components/parts/Menu';
import { useRecoilState } from 'recoil';
import { currentUser0, drawerData0, balance0, notiList0, drawerSend0} from '../../lib/states';
import { getData } from '../../lib/apiHandler';
import Drawer from '../../components/parts/Drawer';
import DrawerSend from '../../components/parts/DrawerSend';
import { coinsData } from '../../lib/dbInfo';
import Noti from '../../components/parts/Noti'
import Banner from '../../components/parts/Banner';
import MessBub from '../../components/parts/MessBub';
import Pop from '../../components/parts/Pop';

function Layout({ children }) {
    const [menuShow, setMenuShow] = useState(false);
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [drawerData, setDrawerData] = useRecoilState(drawerData0)
    const [drawerSend, setDrawerSend] = useRecoilState(drawerSend0)
    const [balance, setBalance] = useRecoilState(balance0)
    const [notiList, setNotiList] = useRecoilState(notiList0)


    const getPublicKey = async () => {
        await getData(`/crypto/BTC`, '')
        await getData(`/crypto/ETH`, '')
    }
    
      useEffect(() => {
        getPublicKey()
      }, [])
    


    const getCurrenUser = async () => {
        const result = await getData(`/user`, '')
        if (result.ok) {
            setCurrentUser(result.data)
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    useEffect(()  =>  {
        getCurrenUser();
    }, [])

    const getTransactions = async () => {
        const result = await getData('/transaction/all', `?userId=${currentUser.id}`)
        if (result.ok) {
            setBalance(prevValue => {
                return {
                    ...prevValue,
                    ...result.data.balance
            }
        })
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }
    useEffect(() => {
        if (currentUser !== null) {
            getTransactions()
        }
    }, [currentUser])




    if (currentUser !== null && balance.all !== null)
    return (
        <div className="min-h-screen bg-[#f8fafb9e]">

            <Banner />
            
            <Header setMenuShow={setMenuShow} />
            <div className="flex flex-row min-h-[calc(100vh-60px)]">
                <Menu menuShow={menuShow} setMenuShow={setMenuShow} />
                <div className={`sm:w-[calc(100%-250px)] ${menuShow ? 'hidden' : 'w-full'} space-y-4 lg:space-y-0 lg:space-x-4 lg:flex pt-[16px] pr-[28px] pb-[16px] pl-[36px]`}>
                    {children}
                </div>
            </div>
            {
                drawerData !== null && (
                    <Drawer />
                )
            }

            {
                drawerSend !== null && (
                    <DrawerSend />
                )
            }



            <Noti />
            <Pop />
            <MessBub />
        </div>
    );
  }
  
export default Layout;
  