import React from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import { GoHomeFill } from 'react-icons/go';
import { IoSettings } from 'react-icons/io5';
import { FaPowerOff } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";

import { coinsData } from '../../lib/dbInfo';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/AuthContext';

import { useRecoilState } from 'recoil';
import { balance0} from '../../lib/states';


export default function Menu({menuShow, setMenuShow}) {
    const navigate = useNavigate()
    const { logout } = useAuth();
    const handleLogout = () => {
        logout();
        window.location.reload()
        //navigate('/')
    };
    const [balance, setBalance] = useRecoilState(balance0)

  return (
    <div className={`sm:w-[270px] ${menuShow ? 'w-[270px]' : 'hidden'} sm:block text-gray-700 `}>

        <div className='flex flex-row justify-between items-center border-b border-[rgb(240,242,247)] ml-6'>
            <div className='flex flex-col py-2'>
                <span className='text-sm font-semibold text-gray-500'>
                    Total Balance
                </span>
                <span className='text-2xl font-bold'>
                    ${balance.all}
                </span>
            </div>
            {/* <div className='border border-[rgb(240,242,247)] rounded-sm w-5 h-5 flex items-center justify-center'>
                <IoIosArrowDown />
            </div> */}
        </div>


        <div className='ml-2 text-gray-500'>

            <Link to={'/home'}  onClick={() => {setMenuShow(false)}}>
                <Il icon={<GoHomeFill className='text-[25px]' />} text="Home" />    
            </Link>

            <div className='flex flex-row justify-between ml-4'>
                <span className='font-semibold'>Portfolio</span>
                <div className='border-b border-[rgb(240,242,247)] w-[145px] relative bottom-2'></div>
            </div>



            <div className=''>
                {
                    coinsData.map((coin, i) => (
                        <Link key={i} to={`/coins/${coin.key}`} onClick={() => {setMenuShow(false)}}>
                            <Il key={i} icon={<coin.Icon className={`text-[24px]`} style={{color: coin.color}} />} text={coin.name} hov={coin.key === 'BTC' ? '#FF372240' : null} />
                        </Link>
                    ))
                }
            </div>

        </div>

        
        <div className='flex flex-row justify-between items-center border-b border-[rgb(240,242,247)] ml-6'>
        </div>
        
        
        <div className='ml-3 text-gray-500' onClick={handleLogout}>
            <Il icon={<FaPowerOff  className='text-[18px] text-red-500' />} text="Logout" />        
        </div>

        
    </div>
  )
}


function Il({icon, text, hov}) {
  return (
    <div 
        className={`flex flex-row items-center space-x-3 py-[10px] px-4 my-2 rounded-md cursor-pointer ${hov == null ? 'hover-blue' : 'hover-orange'}`}
    >
        {icon}
        <span className={`text-lg font-bold ${text === 'Logout' && 'pl-0.5'}`}>
            {text}
        </span>
    </div> 
  )
}
