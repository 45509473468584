import axios from 'axios';
// const base_url = 'http://localhost:8000'
const base_url = 'https://api.coinx.live'




export const getData = async (route, params) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };
  try {
    const response = await axios.get(`${base_url}${route}${params}`, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    if (error.response.status === 403) {
      //alert('token is not valid')
      localStorage.removeItem('token');
      //localStorage.removeItem('isAdmin');
      window.location.reload()
    }
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}



export const postData = async (route, obj) => {
  // Initialize headers with Content-Type
  const headers = {
    "Content-Type": "application/json",
  };

  // Check if the token is present in localStorage
  const token = localStorage.getItem('token');
  if (token) {
    // Include Authorization header with the token if it's not null
    headers["Authorization"] = `Bearer ${token}`;
  }

  // Configure the request with the headers
  const config = { headers };

  try {
    // Send the POST request with the configured headers
    const response = await axios.post(base_url + route, obj, config);
    response.ok = response.status >= 200 && response.status < 300;
  
    return response;
  } catch (error) {
    console.error('error in call:', error);
    
    // Set response.ok to false for error cases
    if (error.response) {
      error.response.ok = false;
      return error.response;
    } else {
      // If there is no error.response, create a custom error response
      return {
        ok: false,
        status: 500,
        data: { message: 'Network error' }
      };
    }
  }
  /*const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };  
  try {
    const response = await axios.post(base_url + route, obj, config);
    response.ok = response.status >= 200 && response.status < 300;
  
    return response;
  } catch (error) {
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
  */
}

export const postDataFile = async (route, obj) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "multipart/form-data",
    }
  };  
  try {
    const response = await axios.post(base_url + route, obj, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}

export const putData = async (route, obj) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };  
  try {
    const response = await axios.put(base_url + route, obj, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}


export const delData = async (route) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };
  try {
    const response = await axios.delete(`${base_url}${route}`, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    if (error.response.status === 403) {
      //alert('token is not valid')
      localStorage.removeItem('token');
      //localStorage.removeItem('isAdmin');
      window.location.reload()
    }
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}