import React from 'react'

export default function RangeSelectorTab({tabState, setTabState}) {
    
  return (
    <div className='bg-[#f0f2f7] flex flex-row rounded-md w-min'>
        {
            listRan.map((item, i) => (
                <Ran key={i} text={item.text} index={i} tabState={tabState} setTabState={setTabState} />
            ))
        }
    </div>
  )
}

const Ran = ({text, index, tabState, setTabState}) => {
    return (
        <div 
            className={`text-gray-500 text-sm font-semibold w-1/4 rounded-md flex justify-center items-center ${index === tabState ? 'bg-white text-blue-600 shadow-md' : 'cursor-pointer'}  m-[3px] py-[10px] w-[75px]`}
            onClick={() => setTabState(index)}
        >
            <span>{text}</span>
        </div>
    )
}


const listRan = [
    {
        text: 'All',
        amountDays: 6
    },
    {
        text: 'Received',
        amountDays: 6
    },
    {
        text: 'sent',
        amountDays: 6
    }
]