import { useEffect, useState } from "react";
import { FaBtc } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BrandName, MenuTerms } from "../../App";




const TermsPage = () => {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        console.log(BrandName)
    }, [])
    

    return (
        <div className="flex flex-col min-h-screen">

            {/* MENU */}
            <MenuTerms />


            <div>
                {/* SEC1 */}
                <div>
                    <div className={`${BrandName === 'Bwallet' ? 'bod2' : 'bod'} ${BrandName === 'coinX' ? 'py-[120px]' : 'py-[50px]'} space-y-10`}>
                        <h1 className='text-king text-[32px] font-bold'>TERMS OF SERVICE</h1>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                                GENERAL PROVISIONS
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            These Terms of Service (“Terms”) constitute an agreement between the customer (“you” or “user”) and Our Company, a private limited company incorporated in Portugal.

{BrandName} is IT company that develops software for end users.

These Terms apply to access and use of the software services as well as other ancillary services (listed below) (“Service” or “Services”) provided by {BrandName} and third party providers - partners of Our Company.

Services are available via a set of mobile, web, desktop applications, browser extension and webpages www.guarda.com (“Website”) (altogether - “Software”).

By using Services and Software and further by accepting to avail the Services, you agree to accept and comply with these Terms. You should read, understand, and accept the entire Terms. If you do not accept and agree to these Terms, you should avoid accessing or using the Services and Software.

Please be aware that the Company may revise these Terms from time to time. Therefore, the Terms may be changed or amended. Any changes or amendments will be published in advance for a reasonable period of time on the Website. You might be notified about the updates of the Terms. Effective date which is at the top of the Terms informs you about the latest version of the Terms. The Company advises you to revisit this page from time to time to make sure you are familiar with the current version of the Terms.

ACCOUNT REGISTRATION. ELIGIBILITY CRITERIA
You may need to register an account to access some or all of our Services (“Account”). Creation. Data provision is governed by our Privacy Policy. Please also get acknowledged with our AML Policy.

Shall you be required to register the Account, You confirm that:

You are at least 18 (eighteen) years old and have full legal capacity;
You are not a resident of a sanctioned jurisdictions according to the EU legislation and FATF lists;
You are not sanctioned by the EU, UN, USA or any authorities in the world;
You are not located in, or a citizen or resident of any state, country, territory or other jurisdiction where your use of the Services is fully illegal and it does not violate any applicable law.
As long as you agree to and comply with these Terms, Guardaco grants to you a right to use Software and Services under a worldwide, non-exclusive, non-transferable, non-sublicensable, and limited license.


                            </p>
                        </div>



                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            
SERVICES
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            {BrandName} offers Services either directly or via third party providers.

3.1. SERVICES OFFERED BY Our Comapny
{BrandName} grants a license to use its self-developed software products - software {BrandName} wallet and {BrandName} browser extension. These Services are offered free of charge.

Company’s Software allows you to create your own wallet for virtual or fiat currency. However, {BrandName} itself doesn’t provide any payment or exchange services. For all other Services, including exchange, staking, loans etc., {BrandName} acts as an intermediary - third party providers are offering services to you directly (for more details please see below).

3.1.1. SOFTWARE "{BrandName} WALLET"
Software "{BrandName} wallet" is an IT solution that allows end users to work with blockchains directly with no interactions from any other counterparties.

{BrandName} wallet enables users to make transactions with virtual currencies and tokens in a non-custodial manner meaning that no one has access to the user's assets and private information. User himself is the only one who can control the wallet and the assets. Company does not store user’s private keys, backup phrases or passwords (“Private Information”). It is very important that you backup Private Information. If you lose Private Information then it will not be possible for the Company to recover it for you and you may lose access to your account (wallet).

Company grants you a non-exclusive, non-transferable worldwide license to use {BrandName} wallet software. You may use it only in compliance with intended purposes under these Terms. You may not reproduce, modify, customize, display or publish it.

{BrandName} wallet as an IT solution provides functionality that allows you to make transactions with virtual currencies. However, Company does not have access to your transactions (or anyone else's). Company is not the creator of and does not have any control over any of the virtual currencies or tokens that {BrandName} wallet allows to use. More than that - all the transactions with these currencies or tokens are not governed by Our Company.

3.2. SERVICES PROVIDED BY THIRD-PARTIES
Via Software Company provides the ability to access third-party services and products, activities or events, and may allow third parties to make their content and information available on or through Services (“Third-Party Integrations”). Company provides Third-Party Integrations as a service to those interested in such content. Your dealings or correspondence with third parties and your use of or interaction with any Third-Party Integrations are solely between you and the third party.

Company does not control or endorse, and makes no representations or warranties regarding any Third-Party Integrations, and your access to and use of such Third-Party Integrations is at your own risk.

In this case {BrandName} merely acts as an information service provider and intermediary between you and third party providers. {BrandName} Services here are limited to technical provision of user interface to facilitate third party Services; {BrandName} is neither a party to these transactions, nor has any rate or commission decision making powers.

{BrandName} doesn't receive any payments from users – all fees paid go from users towards third parties directly. For this reason, {BrandName} can not be responsible for any reimbursements, refunds as well as carry any other financial liability towards users.

Although {BrandName} makes it easy to engage with the integrated third parties, Company shall not be responsible for any consequences stemming from your use of third-party integrated services. If you require assistance with a Third-Party Integration then you should contact a third party directly, yet {BrandName} support team will try to assist you as well if this is possible. {BrandName} may but not obliged to connect you with the responsible third-party to resolve your issue.

{BrandName} and third party providers are independent contractors and are not acting as agents for each other. All of the third party providers are acting on behalf of themselves.


Under conditions of this provider, you must ensure that your transactions conform to the applicable rules of the virtual currency software. There may be transaction fees (e.g. mining fees) associated with your virtual currency transactions that are required by the virtual currency system you engage with. Company and ChangeNOW shall not be responsible for any losses you incur due to transaction fees or losses that occur due to incorrectly set transaction fees (i.e. too low or high).

3.2.1. {BrandName} web-based virtual currency purchase
{BrandName} web-based virtual currency purchase with a banking card is supported by its partners SIMPLEX PAYMENT SERVICES UAB (incorporated in Lithuania) or {BrandName} OÜ (incorporated in Estonia). 

When using this Service, you accept their Terms of Use available at https://www.simplex.com/terms-of-use and https://guardarian.com/terms-of-service and Privacy Policies available at https://www.simplex.com/privacy-policy and https://guardarian.com/privacy-policy.

3.2.2. Hardware Wallets
Hardware wallet device for {BrandName} web-based wallet is supported by Ledger SAS (incorporated in France).  When using this Service, you accept their Terms of Use.

3.2.3. Loans
You may have access to virtual currency loan products which are provided by CoinRabbit (Rabbit Finance Limited, incorporated in St. Vincent and the Our Company). Their website is https://coinrabbit.io/. When using this Service, you accept their Terms of Use available at https://coinrabbit.io/terms-of-use and Privacy Policy available at https://coinrabbit.io/privacy-policy.

3.2.4. Virtual currency rates
All rates available in the {BrandName} wallet are supported by CoinMarketCap (CoinMarketCap OpCo, LLC, incorporated in Delaware, USA) and CryptoCompare (Crypto Coin Comparison Limited, incorporated in the UK). Their websites are https://coinmarketcap.com/ and https://www.cryptocompare.com/ respectively.

When using this Service, you accept their Terms of Use available at https://coinmarketcap.com/terms/ and https://www.cryptocompare.com/terms-conditions/ and Privacy Policy available at https://coinmarketcap.com/privacy/ and https://www.cryptocompare.com/privacy-policy/.

3.2.5. Transaction information
User’s balance and actual transaction history are supported by each virtual currency block explorer e.g. https://ethblockexplorer.org/, etc.

3.2.6. Staking
Staking is the process of delegation funds to a node, which takes part in the governance of a Proof of Stake (PoS) blockchain network. In exchange for offering your assets to be used in ensuring functionality of the blockchain network, owners are to be rewarded with additional coins that are issued by the blockchain in question.

This product is provided by FD Transfers LLC (incorporated in St. Vincent and the Our Company). Their Terms of Use and Privacy Policy can be provided upon request via e-mail post.coincrypto@protonmail.com.

3.2.7. VISA prepaid card
Users can apply for VISA prepaid card and/or virtual card, which is issued by Walletto UAB (incorporated in Lithuania) - e-money institution licensed by the Bank of Lithuania as an Electronic Money Institution. It is also a member of Visa Europe Limited. Their website is https://walletto.eu/.



                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            INFORMATION ABOUT REFUNDS
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">

                            Since {BrandName} is offering only free of charge Services and does not accept any fees from users, it cannot offer any monetary refunds.

From the moment you start using our Software, you explicitly give up your 14-day right of withdrawal, from which consumers benefit.

{BrandName} also cannot be held responsible for refunds for Services performed by third parties since it technically does not have information about your transactions and access to the fees paid.

In case you need a refund, please contact the respective provider from the list above.

Please note that our providers may have limitations for refunds. In particular, all exchanges made for fiat and virtual currency are final. Payments for exchanges made are non-refundable, irrevocable and cannot be changed after the transaction has been completed and cryptocurrency is sent to the wallet address specified by you, this is why it is important to confirm the correct wallet address at the time of transaction. Only transactions which have not been successful can be refunded. Refunds will not be done for incomplete or incorrect data by you, data mismatch or other suspicious circumstances. In case of high risk transactions (Stolen funds, Terrorist Financing, Money laundering etc), providers may freeze the funds or cryptocurrency for detailed investigation. For virtual currency to fiat transactions, refund will only be done to the wallet address provided in the ‘refund address’ field if there is a need for a refund. If you deliberately fail your transactions in a suspicious manner, providers will freeze such funds. Refund commissions might apply.

If you are in a member state of the European Union, you have the right to request refunds for certain Services our partners provide within 14 days without giving a reason.

To exercise your right of withdrawal, you must inform your partner, as {BrandName} does not administer payments and refunds.

You may use the opt-out form below as a sample:

I hereby give notice that I am terminating my contract as follows: [INSERT ORDER ID, ITEM] - Ordered [INSERT DATE] / Received [INSERT DATE]
User’s Name
User's email address
Date
To exercise your right of withdrawal in a timely manner, you must contact our third party provider who was offering you the respective Service prior to the expiration of your right of withdrawal. If you exercise your statutory right of withdrawal, you will be refunded without undue delay and in any event no later than 14 days from the date partner is informed of your decision to withdraw.


                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            
USERS WARRANTIES




                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">

                            You may not use Services if you are located in, or a citizen or resident of, any other jurisdiction where Company has determined, at its discretion, to prohibit the use of the Services. We may implement controls to restrict access to the Services from any jurisdiction prohibited pursuant to these Terms.

By using our Services you confirm that you have provided true and accurate data about yourself during the account registration or during the usage of the Services. You shall update the information if the submitted information is not accurate anymore. You shall bear any losses that occur due to submission of invalid data.

You agree that Company has the right to require information which helps us to identify and verify you. Company has a right to suspend your usage of the website in case you fail to submit the requested information or submitted information is considered to be inaccurate or incomplete.

You are only transacting via Services with legally-obtained funds that rightfully belong to you.




                            </p>
                        </div>




                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            LIMITED RIGHT OF USE. INTELLECTUAL PROPERTY
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Unless otherwise specified, all materials in Software are the property of the Company and are protected by copyright, trademark and other applicable laws. You may view, print and/or download a copy of the materials from this Website on any single computer solely for your personal, informational and/or non-commercial use, provided you comply with all copyright and other proprietary notices. The trademarks, service marks and logos of the Company and others used in Software (“Trademarks”) are the property of the Company and their respective owners.

Please note that Trademarks include the registered European trademark..

Trademarks and other materials should not be copied, reproduced, modified, republished, uploaded, posted, transmitted, scraped, collected or distributed in any form or by any means, whether manual or automated.

When accessing Service, you shall not: (i) negatively interfere with other users; (ii) damage, disable or in any way disrupt Software or Services; (iii) conduct, pay for, support or in any way be involved in any illegal activities, including but not limited to money laundering, terrorist financing, fraud, illegal gambling, illegal weapons sale and drug trafficking; (iv) use any automated means or interface to access Services or to extract data; (v) use another user account; (vi) provide false or misleading information to us.






                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            
RISK WARNING

                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            {BrandName} understands that our third party partner might offer virtual currency related Services that is why we would like to give you the following warning. We highly recommend learning the basics of the specific virtual currency that you plan on using before running the application. Trading and investing in virtual currencies involve substantial risk of loss and is not suitable for all types of investors. Please make sure you are investing mindfully after understanding the nature, complexity and risks inherent in the trading of virtual currency. You should not purchase virtual currency unless you understand the extent of your exposure to potential loss. Please make sure you are not risking funds you cannot afford to lose. In no event shall the Company be liable to any loss or damage of any kind incurred as a result of the use of this Website or the Services.

To ensure control over security risks, Company has implemented the best security practices aiming to decrease security risk level in using the Software to the lowest possible level and is updating and monitoring them constantly. However, no one can be 100% safe from security risks, therefore, it is very important that not only Company, but also the user acts with due care when it comes to preventing security incidents from taking place.

To mitigate security risks, it is important to understand how they may occur and what may have an impact on their occurrence. In addition, technical problems may occur in your computer or the internet being used for Services or even in the network of mobile telephone operators which can prevent or suspend the use of the Services. All these actions can lead to losses for the user as well as be a part of other illegal actions, including money laundering / terrorist financing.

You are responsible for preserving the device used to access our Services. To receive Services in a compliant and safe manner, you undertake the following main responsibilities:

Not to leave devices and/or Identifiers accessible to third parties or allow third parties have access to these devices or identifiers;
To ensure all measures ensure optimal security of your equipment being used to gain Services (for instance, to install antivirus software, anti-spyware, firewalls,etc.);
Take all other measures and make actions that could be reasonably expected from you wishing to ensure safety of your credentials and identifiers and not to make your account or Services available to unauthorized third parties.
LIMITATION OF LIABILITIES. INDEMNITIES
To the extent permitted by law, Company shall not be liable for any damages, losses of profit, usage or data, loss of business, loss of business, failure to use the Services, users misunderstanding of Services or any other loss originated from negligence or fraud or otherwise arising out of or connected to the Services or these Terms.

{BrandName} is liable under the law for (a) intentional misconduct; (b) gross negligence; (c) violation of applicable product liability laws. Subject to this, Guardaco may only be liable for breach of a material contractual obligation under the Terms, breach of which would jeopardize the purpose of the Terms. In such an event, Guardaco’s liability is common in such situations and foreseeable damages. In other circumstances, Guardaco shall not be liable for acts of negligence.

You shall indemnify and hold harmless Company, its affiliates, contractors, licensors and respective shareholders, members, directors, officers, employees, attorneys, agents and suppliers from any claim, damage, lawsuit, tort, cost or expenses.

Service is provided on an “as is” and “as available” basis without any warranty or representation expressed or implied. Company does not make any representations or give warranties that the access to Software, use of the Service, functionality of Account will be continuous, uninterrupted, timely or error-free.

You understand and agree that Company shall not be liable in connection with any force majeure event, including labour disputes or other industrial disturbances, electrical, telecommunications, hardware, software or other utility failures, software or smart contract bugs or weaknesses, earthquakes, storms, or other nature-related events, blockages, embargoes, riots, strikes, acts or orders of government authority, acts of terrorism or war, technological change, changes in interest rates or virtual currencies or other monetary conditions, and, for the avoidance of doubt, changes to any blockchain-related protocol.

TAXES. COMPLIANCE WITH LOCAL REGULATIONS
{BrandName} undertakes to comply with applicable law.

It is your responsibility to comply with local laws in respect to the legal usage of Services and the regulatory qualification of virtual currencies in your jurisdiction.

Likewise, Company expects the same of its third parties, but it is unable to control their Services, nor does it have the technical, legal or any other ability to ensure they comply with all applicable laws, rules, regulations, and license requirements. It is not Company’s responsibility to conduct due diligence on partners, and it cannot be held responsible in any circumstances if partners are found to be in violation of applicable laws, rules, and regulations as well as official license requirements.

You are responsible for determining what taxes apply and in which measure to the authorized use of Services. You should report and remit the correct taxes to the appropriate tax authority. Company is not responsible for determining your tax obligations.





                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            MISCELLANEOUS

                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            These Terms set forth the entire understanding between Company and you with respect to the Service. You agree to rely only on Terms alone. These Terms supersede any previous statements made by Company.

If any term, clause or provision of these Terms is held unlawful, void or unenforceable, then that term, clause or provision will be severable from these Terms and will not affect the validity or enforceability of any remaining part of that term, clause or provision, or any other term, clause or provision of these Terms.

No failure of Company to exercise any right, power or privilege under these Terms is deemed to be a waiver thereof, nor any partial exercise of a right, power or privilege should preclude the exercise of the entire or other right, power or privilege.

You may not assign any of the rights conferred to you under these Terms without prior written consent from Company. Company may assign any right or obligation under these Terms without any notice to or consent from you.

These Terms are provided in English. Any translation has the sole purpose of being convenient for you. In case of any inconsistency, contradiction or doubt, English version of these Terms shall prevail.






                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            JURISDICTION
                                                        </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            The Terms shall be governed and construed in accordance with the law of Portugal.

Users agree that any dispute arising in connection Services, or in any way related to them, may be resolved through negotiations, or using the European Online Dispute Resolution Platform (“ODR Platform”). The ODR platform is specifically designed to assist consumers who purchase goods or services online and encounter problems with such online purchases, and allows contractual disputes to be resolved through the Alternative Dispute Resolution (ADR) procedure online at https://Webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage.

If you cannot resolve the dispute through negotiation, failure to approve an amicable settlement, or failure to resolve the dispute through ADR, you have the right to file a lawsuit in the regular course of action. You hereto agree to irrevocably submit to the exclusive jurisdiction of the courts of Portugal.




                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            INFORMATION ABOUT REFUNDS
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                                
                            </p>
                        </div>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            TERMINATION AND SUSPENSION
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            You may terminate the agreement with Company at any time on the basis provided by law.

You agree that Company may terminate the agreement with you and suspend or cancel your Account in case of, but not limited to,

non-conformity to the requirements from these Terms,
a requirement from any applicable law to which the Company is subject in any jurisdiction,
an order from a court or other governmental authority,
unusual, unauthorized or fraudulent activity in your Account,
false, misleading, inaccurate, incomplete or outdated information,
any other reasons that the Company deems relevant for termination of the Terms between you and Company and suspension or cancelation of your Account.
Company shall have the right to file claims against you or your legal representative for compensation. Upon termination, suspension or cancellation on the basis of the previous clauses, you will be denied access to Services temporary or permanently.




                            </p>
                        </div>


                    </div>
                </div>
            </div>    
        </div>
    )
}   


export default TermsPage