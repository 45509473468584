import React, { useEffect, useState } from 'react'
import Line from '../charts/Line'
import RangeSelector from '../home/RangeSelectorTime'
import CoinsSelectior from '../home/CoinsSelectior'
import { dateToUnixTime, formatNumberWithCommas, getHistoryCoin, subtractDays } from '../../lib/funcs'
import { useRecoilState } from 'recoil'
import { drawerData0, drawerSend0 } from '../../lib/states'

export default function ConHistory() {
    const [range, setRange] = useState(365)
    const [start, setStart] = useState(dateToUnixTime(subtractDays(new Date(), range)))
    const [end, setEnd] = useState(dateToUnixTime(new Date()))
    const [data, setData] = useState([])
    const [choosedCoin, setChoosedCoin] = useState(0)

    
    useEffect(() => {
      setStart(dateToUnixTime(subtractDays(new Date(), range)))
    }, [range])
    
    useEffect(() => {
      const func = async () => {
        const result = await getHistoryCoin(start, end, choosedCoin)
        setData(result)
      }
      func()
    }, [start, choosedCoin])

    

  return (
    <div 
      style={{height: 'min-content'}}
    >
      
      <Topper data={data} choosedCoin={choosedCoin} setChoosedCoin={setChoosedCoin} />

      <div className='h-[200px]' >
        <Line data={data} choosedCoin={choosedCoin} />
      </div>
      <div className='flex justify-end'>
        <RangeSelector setRange={setRange} />
      </div>
    </div>
)
}


const Topper = ({data, choosedCoin, setChoosedCoin}) => {
  const [drawerData, setDrawerData] = useRecoilState(drawerData0)
  const [drawerSend, setDrawerSend] = useRecoilState(drawerSend0)

  const [temp, setTemp] = useState(0)

  useEffect(() => {
      if (data.length !== 0) {
          setTemp(formatNumberWithCommas(data[data.length-1].pv))
      }
  }, [data])

  return (
    <div className='h-[150px] m-7 flex flex-col md:flex-row justify-between h-min'>
      <CoinsSelectior choosedCoin={choosedCoin} setChoosedCoin={setChoosedCoin} data={data} />
      <div className='flex flex-row [&>button]:btn space-x-2 lg:w-[55%]  xl:ml-12 w-full [&>button]:w-1/2  '>
        <button className='btn-blue' onClick={() => {setDrawerData(true)}}>Receive</button>
        <button className='btn-emp' onClick={() => {setDrawerSend(temp)}}>Send</button>
        {/* <button className='btn-emp'>Swap</button> */}
      </div>
    </div>
  )
}
