import React from 'react'
import { FiMenu } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { drawerData0, isAdmin0 } from '../../lib/states';

import { FaBtc } from "react-icons/fa";
import { Logo } from '../../App';


export default function Header({setMenuShow, toPanel}) {
    const [drawerData, setDrawerData] = useRecoilState(drawerData0)
    const [isAdmin, setIsAdmin] = useRecoilState(isAdmin0);
    const navigate = useNavigate();
    const location = useLocation();

    // const handleDrawer = async () => {
    //     setDrawerData(true)
    // }

  return (
    <div className="h-[60px] flex flex-row items-center px-2 sm:px-6 justify-between border-b border-[rgb(240,242,247)] bg-[#f8fafb9e]">
        <div className='flex flex-row'>
            <div className='sm:hidden flex items-center mr-4'>
                <button className='' onClick={() => setMenuShow(prevValue => !prevValue)}>
                    <FiMenu className='text-[25px] text-blue-600' />
                </button>
            </div>
            <div className='flex flex-row space-x-2'>
                {/* <Link to={'/'} className='text-[24px]'>
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m2.05353 8.72192-1.094996 1.09501c-.303074.29117-.5442.64057-.708915 1.02717-.1647162.3866-.249619.8026-.249619 1.2228 0 .4203.0849028.8362.249619 1.2229.164715.3866.405841.736.708915 1.0271l8.821476 8.9086c.20908.2139.45239.3913.71999.525v-10.7986z" fill="#619ff7"/><path d="m21.9465 8.72192 1.095 1.09501c.303.29117.5442.64057.7089 1.02717s.2496.8026.2496 1.2228c0 .4203-.0849.8362-.2496 1.2229-.1647.3866-.4059.736-.7089 1.0271l-8.8215 8.9086c-.2091.2139-.4524.3913-.72.525v-10.7986z" fill="#3d89f5"/><path d="m19.8286 6.48695-5.52-5.534996c-.2923-.301193-.642-.540658-1.0285-.704178-.3865-.1635204-.8019-.247776-1.2215-.247776-.4197 0-.8351.0842556-1.2216.247776-.3865.16352-.7362.402985-1.02844.704178l-5.535 5.534996 7.75504 3.86995z" fill="#85b5f8"/></svg>
                </Link> */}


                <span onClick={toPanel ? () => navigate('/panel') : () => navigate('/')} className={`text-2xl cursor-pointer font-semibold ${true ? 'text-king' : 'text-white'} flex flex-row items-center`}>
                    <Logo />
                </span>
            </div>
        </div>
        <div className='[&>button]:but flex flex-row space-x-2'>
            {/* <button>Send</button> */}

            {/* <button onClick={handleDrawer}>Receive</button> */}
            {   
                isAdmin && (
                    location.pathname.substring(0,6) !== '/panel' ? (
                        <>
                            <button onClick={() => navigate('/panel')}>admin panel</button>
                        </>
                    ) : (
                        <>
                            <button onClick={() => navigate('/panel/address')}>address</button>
                            <button onClick={() => navigate('/home')}>home</button>
                        </>
                    )
                )
            }

            {/* <button>Trade</button> */}
        </div>
    </div>
  )
}
