import { useRecoilState } from "recoil"
import { banner0, currentUser0 } from "../../lib/states"
import { MdMessage } from "react-icons/md";
import { useEffect, useState } from "react";
import { IoInformationCircle } from "react-icons/io5";
import { getData } from "../../lib/apiHandler";


export default function Banner() {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    
    const [banner, setBanner] = useState('')

    useEffect(() => {
        setBanner(currentUser.banner)
    }, [])


    if (banner !== '') return (
        <div class="z-50 flex sticky top-0 justify-between w-full p-4 border-b border-gray-200 bg-orange-100">
            <div class="flex items-center mx-auto">
                <p class="flex items-center text-sm font-semibold text-gray-600 space-x-1 flex items-center">
                    <IoInformationCircle className="h-6 w-6 text-orange-500" />
                    <span>{currentUser.banner}</span>
                </p>
            </div>
            <div class="flex items-center">
                <button onClick={() => setBanner('')} type="button" class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5">
                    <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close banner</span>
                </button>
            </div>
        </div>
    )
}
