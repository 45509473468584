import React, { useEffect } from 'react'
import { coinsData } from '../../lib/dbInfo'

export default function DropCoins({choosedCoin, setChoosedCoin, drop, setDrop}) {
    

    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            while (true) {
                if (`drop-coins-list` === element.id) {
                    isInside = true
                    break
                }
                element = element.parentElement
                if (element === null) {
                    break
                }
            }
            if (!isInside) {
                setDrop(false)
            }
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [])


  return (
    <div className={`relative top-7 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${!drop &&  'hidden'}`}>
        <ul className="p-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            {
                coinsData.map((coin, i) => (
                    <li 
                    key={i} 
                    onClick={() => setChoosedCoin(i)}
                    className='flex cursor-pointer flex-row space-x-2 items-center hover:bg-gray-100 rounded-lg px-2 py-2'
                    >
                        <coin.Icon className={`text-[22px]`} 
                            style={{color: coin.color}}
                        />
                        <span>{coin.name}</span>
                    </li>
                )
                )
            }
        </ul>
    </div>
  )
}
