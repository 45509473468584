import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { notiList0 } from '../../lib/states';
import { AiOutlineClose } from 'react-icons/ai';

export default function Noti() {
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const remNoti = (index) => {
        setNotiList(prevValue => prevValue.filter((item, i) => i != index))
    }
  return (
    <div className='fixed bottom-[60px] md:bottom-0 right-4 left-4 sm:right-[85px] md:right-[80px] md:left-auto  z-40'>
        {
            notiList.map((item, i) => {
                const color = item.isSucc ? {
                    bg: '#f0fdf4',
                    colo: '#166534',
                    bord: '#86efac'
                } : {
                    bg: '#fef2f2',
                    colo: '#991b1b',
                    bord: '#fca5a5'
                }

                return (
                    <div key={i} 
                    style={{
                        backgroundColor: color.bg,
                        color: color.colo,
                        borderColor: color.bord
                    }} className={`z-20 flex flex-row justify-between items-center mb-4 text-sm border rounded-lg `}>
                        <div className='flex flex-row items-center justify-center p-4'>
                            <svg className="flex-shrink-0 inline w-4 h-4 mr-3 relative top-[1px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                            </svg>
                            <div>
                                <span className="font-medium">{item.text}</span>
                            </div>
                        </div>
                        <div className='border-green-200 px-4 h-[50px] w-[50px] flex items-center justify-center'>
                            <span className='hoverEffect relative top-[1px] cursor-pointer' onClick={() => remNoti(i)}>
                                <AiOutlineClose className='text-md m-2' />
                            </span>
                        </div>
                    </div>
                )
            })
        }
    </div>
  )
}
