
function LoadingPage() {
    return (
        <div>
        <h1 className="text-3xl font-bold underline">
          Loading...
        </h1>
      </div>
    );
}
  
export default LoadingPage;
  