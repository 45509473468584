import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiSolidMessageDetail } from "react-icons/bi";

function Table({data, ths, mess}) {
  const navigate = useNavigate()

//   useEffect(() => {
//     // console.log(mess)
//     console.log(mess.filter((me, m) => me.userId === 11 && me.isRead === 0 && me.isAdminSend === 0) )
//   }, [mess])

    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                {
                    ths.map((th,i) => (
                    <th key={i} scope="col" className="px-4 py-3">{th}</th>
                    ))
                }
                </tr>
            </thead>
            <tbody>
            {
                data !== null && data.data.map((line, z) => (
                <tr key={z} className="border-b dark:border-gray-700">
                    {
                    ths.map((th,i) => (
                        <td className={`px-4 py-3 ${th === 'firstName' && 'font-semibold cursor-pointer'}`} onClick={th === 'firstName' ? () => navigate(`/panel/user/${line['id']}`) : () => {}} key={i}>
                            {
                                line[th] === 0 && <span className='text-red-400'>
                                    false
                                </span>
                            }
                            {
                                line[th] === 1 && th !== 'id' && <span className='text-green-400'>
                                    true
                                </span>
                            }
                            {
                                line[th] !== 0 && line[th] !== 1 && (
                                    line[th]
                                )
                            }
                            {
                                th === 'id' && line[th] === 1 && (
                                    line[th]
                                )
                            }
                            {
                                th === 'mess' && (
                                    mess.filter((me, m) => me.userId === line['id'] && me.isRead === 0 && me.isAdminSend === 0).length === 0 ? (
                                            <span></span>
                                        ) : (
                                            <div className='flex justify-center'>
                                                <span><BiSolidMessageDetail className='text-blue-700 text-xl' /></span>
                                            </div>
                                        )
                                )
                            }
                        </td>
                    ))
                    }
                </tr>
                ))
            }
            </tbody>
        </table>
    );
  }
  
  export default Table;
  