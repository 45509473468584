import { useRecoilState } from 'recoil';
import { drawerSend0, currentUser0 } from '../../lib/states';
import { useEffect, useRef, useState  } from 'react';
import { getData, postData } from '../../lib/apiHandler';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import { coinsData } from '../../lib/dbInfo';
import { notiList0 } from '../../lib/states';
import { formDataToObject } from '../../lib/funcs';




function DrawerSend() {
    const [drawerSend, setDrawerSend] = useRecoilState(drawerSend0)
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [address, setAddress] = useState(null)
    const isMounted = useRef(true);
    const location = useLocation();

    

    const [notiList, setNotiList] = useRecoilState(notiList0)

    
    var coinKey = location.pathname.substring(7)
    if (coinKey === '') {
        coinKey = 'BTC'
    }    


    const getPublicKey = async () => {
        const result = await getData(`/crypto/${coinKey}`, '')
        if (result.ok) {
            setAddress(result.data.data.publicKey)
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }


    useEffect(() => {
        getPublicKey()
    }, [])


    var coin = coinsData.find(coin => coin.key === coinKey)


    function convertStringToNumber(str) {
        if (typeof str === typeof 'a') {
            const stringWithoutCommas = str.replace(/,/g, '');
            const number = parseFloat(stringWithoutCommas);
            return number;
        }
        console.log(str)
        return str
        }


    const closeDrawer = () => {
        setDrawerSend(null)
    }

    const sendCrypto = async (e) => {
        e.preventDefault();
        var formData = formDataToObject(new FormData(e.target));

        if (formData.keyAmount > 0) {
            formData.keyAmount = -formData.keyAmount
        }
        if (formData.usdAmount > 0) {
            formData.usdAmount = -formData.usdAmount
        }

        const result = await postData('/transaction', {
            ...formData,
            coinKey: coinKey,
            date: new Date(),
            time: new Date(),
            userId: currentUser.id,
            from: address,
            status: 'Pending'
        })

        if (result.ok) {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}])
            window.location.reload()
            //closeDrawer()
            
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
    }

    const [dollarAmount, setDollarAmount] = useState('');
    const [bitcoinAmount, setBitcoinAmount] = useState('');


    const convertDollarsToBitcoin = (amount) => {
        const dollars = parseFloat(amount);
        const convertedBitcoin = dollars / convertStringToNumber(drawerSend);
        if (isNaN(convertedBitcoin)) {
            return 0
        } else {
            return convertedBitcoin.toFixed(8)
        }
    };

    const convertBitcoinToDollars = (amount) => {
        const bitcoin = parseFloat(amount);
        const convertedDollars = bitcoin * convertStringToNumber(drawerSend);
        if (isNaN(convertedDollars)) {
            return 0
        } else {
            return convertedDollars.toFixed(8)
        }
    };


    return (
        <div className="w-full h-screen bg-[#a9a9a94f] fixed top-0 text-gray-800" onClick={closeDrawer}>
            <div className={`bg-white fixed top-0 right-0 z-40 h-screen  overflow-y-auto  bg-white w-[500px]`} onClick={(e) => e.stopPropagation()} tabIndex="-1" aria-labelledby="drawer-right-label">


                <div className="text-2xl font-semibold mx-10 my-4 flex flex-row space-x-5 flex flex-row items-center">
                    <FaArrowLeft className='text-gray-600 cursor-pointer text-md' onClick={closeDrawer} />
                    <span>
                        Send
                    </span>
                </div>

                <form className='space-y-4' onSubmit={sendCrypto} autoComplete='off'>
                    <div className='border-t px-10 py-4 space-y-3'>
                        <Il icon={<coin.Icon className={`text-[30px]`} style={{color: coin.color}} />} text={coin.name} />

                        <div className='flex flex-col font-semibold space-y-2'>
                            <div>
                                <span className='text-gray-600'>Address</span>
                                <input className='inp' type='text' name='to' required />
                            </div> 

                            <div className='flex flex-row items-end [&>div]:w-1/2 space-x-2'>
                                <div>
                                    <span className='text-gray-600'>USD amount</span>
                                    <input className='inp' type='text' pattern='[0-9]+(\.[0-9]+)?' value={dollarAmount}
                                        onChange={(e) => {setDollarAmount(e.target.value); setBitcoinAmount(convertDollarsToBitcoin(e.target.value));}}
                                        name='usdAmount' required
                                    />
                                </div> 
                                <span className='h-[35px]'>=</span>
                                <div>
                                    {/* ${isValidBitcoinAddress(bitcoinAmount) ? 'bg-red-500' : 'bg-green-500'} */}
                                    <span className='text-gray-600'>Currency amount</span>
                                    <input className={`inp `} type='text' pattern='[0-9]+(\.[0-9]+)?' value={bitcoinAmount} 
                                        onChange={(e) => {setBitcoinAmount(e.target.value); setDollarAmount(Number(convertBitcoinToDollars(e.target.value)).toFixed(2))}}
                                        name='keyAmount' required
                                    />
                                </div> 
                            </div>



                        </div>
                    </div>

                    <div className='px-10 pb-1'>
                        <button type='submit' className='btn btn-blue w-full'>
                            Send
                        </button>
                    </div>
                </form>

            </div>
        </div>
    );
}
  
export default DrawerSend;

function Il({icon, text, hov}) {
    return (
      <div 
          className={`flex flex-row items-center space-x-3 py-[10px]`}
      >
          {icon}
          <span className='text-lg font-bold'>
              {text}
          </span>
      </div> 
    )
}
  