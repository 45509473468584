import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { postData, postDataFile, putData } from '../../../lib/apiHandler';


import { BsInfoCircle } from "react-icons/bs";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoDocumentOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaBtc } from "react-icons/fa";
import QRCode from "react-qr-code";
import { regLevel0 } from "../../../lib/states";
import { useRecoilState } from "recoil";
import { BrandName, Logo } from "../../../App";
import PrefixInp from "../../../components/parts/PrefixInp";

export default function Register() {
    const navigate = useNavigate()
    const [level, setLevel] = useState(0)///////////////////////////
    const [regLevel, setRegLevel] = useRecoilState(regLevel0)
    const [obj, setObj] = useState({
        phone: '',
    })

    useEffect(() => {
        if (regLevel.level === 2) {
            setLevel(2)
        }
        if (regLevel.level === 4) {
            setLevel(4)
        }
    }, [regLevel])


    //#071278 4290eb
    return (
        <div className="min-h-screen flex flex-col lg:flex-row bg-blue-200 space-y-8 lg:space-y-0 p-5 lg:p-0">
            <div className="lg:w-[30%] flex flex-col lg:p-10 lg:py-7 lg:pr-0 text-white text-lg space-y-10 flex-grow">

                <div className="cursor-pointer flex flex-row items-center text-white" onClick={() => navigate('/')}>
                    <span className={`bg-white rounded-full px-3 py-[2px]`}>
                        <Logo color="text-blue-800 my-[6px]" />
                    </span>
                </div>

                <div className="flex flex-grow items-center text-xl font-semibold justify-center">
                    <ul className="w-full max-w-[690px] lg:space-y-9 flex flex-row justify-between lg:flex-col">
                        <Li icon={<BsInfoCircle />} index={0} level={level} />
                        <Li icon={<RiLockPasswordLine />} index={1} level={level} />
                        <Li icon={<IoDocumentOutline />} index={2} level={level} />
                        {/* <Li icon={<RiMoneyDollarCircleLine  />} index={3} level={level} /> */}
                    </ul>
                </div>

                <div className="text-md text-gray-700 space-x-2 hidden lg:block">
                    <span className=''>Alredy have Account?</span>
                    <Link to="/login" className='font-semibold text-bg-800 hover:underline'>Sign in now</Link>
                </div>
            </div>
            <div className="lg:w-[70%] flex items-center justify-center flex-grow">
                <div className="con p-8 w-full max-w-[700px] space-y-8">
                    {
                        level === 0 && (
                            <Level0 setLevel={setLevel} obj={obj} setObj={setObj} />
                        )
                    }
                    {
                        level === 1 && (
                            <Level1 setLevel={setLevel} obj={obj} setObj={setObj} />
                        )
                    }
                    {
                        level === 2 && (
                            <Level2 setLevel={setLevel} obj={obj} setObj={setObj} />
                        )
                    }
                    {/* {
                        level === 3 && (
                            <Level3 setLevel={setLevel} obj={obj} setObj={setObj} />
                        )
                    } */}
                    {
                        level === 4 && (
                            <Level4 obj={obj} />
                        )
                    }
                </div>
            </div>

            <div className="text-md text-gray-200 space-x-2 lg:hidden text-center">
                <span className=''>Alredy have Account?</span>
                <Link to="/login" className='font-semibold text-white hover:underline'>Sign in now</Link>
            </div>
            
        </div>
    )
}

///////////// LEVELS /////////////////

const Level0 = ({setLevel, obj, setObj}) => {
    const [errText, setErrText] = useState('')

    const handleReg0 = (e) => {
        e.preventDefault()
        var formData = new FormData(e.target);
        setObj({
            ...obj,
            ...formDataToObject(formData)})
        setErrText('')
        setLevel(1)
    }

    return (
        <>
            <div className="flex flex-row justify-between items-center">
                <h1 className="text-3xl font-semibold">
                    Personal information
                </h1>
                <span className="font-semibold text-red-600">
                    {errText}
                </span>
            </div>
            <form className="flex flex-grow flex-col space-y-8" onSubmit={handleReg0} autoComplete="off">
                <div className="grid lg:grid-cols-2 gap-4 w-full">
                    <div className="">
                        <label className="text-gray-500">First Name</label>
                        <input className="inp" type="text" name="firstName" defaultValue={obj['firstName']} required />
                    </div>
                    <div className="">
                        <label className="text-gray-500">Last Name</label>
                        <input className="inp" type="text" name="lastName" defaultValue={obj['lastName']} required />
                    </div>
                    <div className="">
                        <label className="text-gray-500">Phone</label>

                        <PrefixInp />





                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <div></div>
                    <button type="submit" className="btn btn-blue w-[110px]">Next</button>
                </div>
            </form>
        </>
    )
}

const Level1 = ({setLevel, obj, setObj}) => {
    const [errText, setErrText] = useState('')
    const [lod, setLod] = useState(false)
    const handleReg1 = async (e) => {
        e.preventDefault()
        setLod(true)
        setErrText('')
        var formData = new FormData(e.target);
        var newObj = {
            ...obj,
            ...formDataToObject(formData),
            phone: obj['phone'],
            fileName: BrandName
        }
        setObj(newObj)
        const result = await postData('/user/register', newObj)
        if (result.ok) {
            setObj(prevValue => {
                return {
                    ...prevValue,
                    userId: result.data.userId
                }
            })
            setErrText('')
            setLod(false)
            setLevel(2)
        } else {
            setLod(false)
            setErrText(result.data.message)
        }
        
    }
    return (
        <>
            <div className="flex flex-row justify-between items-center">
                <h1 className="text-3xl font-semibold">
                    Login details
                </h1>
                <span className="font-semibold text-red-600">
                    {errText}
                </span>
            </div>
            <form className="flex flex-grow flex-col space-y-8" onSubmit={handleReg1} autoComplete="off">
                <div className="grid lg:grid-cols-2 gap-4 w-full">
                    <div className="">
                        <label className="text-gray-500">Email</label>
                        <input className="inp" type="email" name="email" defaultValue={obj['email']} required />
                    </div>
                    <div></div>
                    <div className="">
                        <label className="text-gray-500">Password</label>
                        <input className="inp" type="password" name="password1" defaultValue={obj['password1']} required />
                    </div>
                    <div className="">
                        <label className="text-gray-500">Confirm Password</label>
                        <input className="inp" type="password" name="password2" defaultValue={obj['password2']} required />
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <button type="button" className="btn btn-emp border-2 w-[110px]" onClick={() => setLevel(0)}>Prev</button>
                    <button type="submit" className="btn btn-blue w-[110px] disabled:btn-blue-lod" disabled={lod}>Next</button>
                </div>
            </form>
        </>
    )
}


const Level2 = ({setLevel, obj, setObj}) => {
    const [errText, setErrText] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [lod, setLod] = useState(false)

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const [regLevel, setRegLevel] = useRecoilState(regLevel0)

    const handleReg2 = async (e) => {

        e.preventDefault()
        setLod(true)
        setErrText('')
        try {
            const formData = new FormData();
            formData.append('image', selectedFile);
            formData.append('userId', obj.userId || regLevel.userId);
            const result = await postDataFile('/user/upload', formData)
            if (result.ok) {
                setErrText('')
                setLod(false)
                setLevel(4)
            }
        } catch (error) {
            setLod(false)
            setErrText('Somthing went wrong')
            console.error('Error uploading image: ', error);
        } 
    }

    return (
        <>
            <div className="flex flex-row justify-between items-center">
                <h1 className="text-3xl font-semibold">
                    Documents Verification 
                </h1>
                <span className="font-semibold text-red-600">
                    {errText}
                </span>
            </div>
            <form className="flex flex-grow flex-col space-y-8" onSubmit={handleReg2} autoComplete="off">
                <div className="grid gap-4 w-full">
                    {/* lg:grid-cols-2 */}

                    <div className="">
                        <label className="text-gray-500">Identification type</label>
                        <select className="inp">
                            <option>ID</option>
                            <option>passport</option>
                            <option>Driving License</option>
                        </select>
                    </div>

                    <div>

                    </div>

                    <div className="flex justify-center w-full flex flex-col">
                        <label className="text-gray-500">ID document</label>
                        <label 
                            htmlhtmlFor="dropzone-file" 
                            className={`flex flex-col items-center justify-center w-full h-[200px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ${selectedFile !== null ? 'bg-blue-100' : 'bg-gray-50'}  hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600`}
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG or JPEG (MAX. 800x400px)</p>
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} />
                        </label>
                    </div> 


                </div>
                <div className="flex flex-row justify-between">
                    <div></div>
                    {/* <button type="button" className="btn btn-emp border-2 w-[110px] disabled:bg-gray-100 border-0" disabled={true}>Prev</button> */}
                    <button type="submit" className="btn btn-blue w-[110px] disabled:btn-blue-lod" disabled={lod || selectedFile === null}>Next</button>
                </div>
            </form>
        </>
    )
}



const Level3 = ({setLevel, obj, setObj}) => {
    const [errText, setErrText] = useState('')
    const [balance, setBalance] = useState(0)

    // useEffect(() => {
    //     const fetchBitcoinBalance = async () => {
    //         try {
    //           const apiUrl = `https://blockchain.info/q/addressbalance/${obj.regFeesAddressPub}?confirmations=6`;
    //           const response = await fetch(apiUrl);
    //           if (response.ok) {
    //             const data = await response.json();
    //             setBalance(data);
    //           } else {
    //             setBalance(0);
    //             console.error('Failed to fetch Bitcoin balance');
    //           }
    //         } catch (error) {
    //           console.error('Error during fetch:', error);
    //         }
    //     };
    //     const intervalId = setInterval(() => {
    //         if (obj.regFeesAddressPub !== null) {
    //             fetchBitcoinBalance()
    //         }
    //     }, 30000);
    
    //     return () => clearInterval(intervalId);
    // }, []);

      useEffect(() => {
        if (balance > 0) {
            updateBalance()
        }
    }, [balance])
    const updateBalance = async () => {
        const res = await putData(`/user/balanceFess`, {
            userId: obj['userId'],
            balance: balance
        })
        setLevel(4)
    }
    return (
        <>
            <div className="flex flex-row justify-between items-center">
                <h1 className="text-3xl font-semibold">
                    Registration fees
                </h1>
                <span className="font-semibold text-red-600">
                    {errText}
                </span>
            </div>
            <form className="flex flex-grow flex-col space-y-8" onSubmit={() => {}} autoComplete="off">
                <div className="w-full flex flex-col items-center justify-center">
                    <span className="w-full max-w-[400px] text-center text-gray-700 text-lg">
                        You must pay the registration fee in order to complete the registration process
                    </span>

                    <QRCode value={'obj.regFeesAddressPub'} className='w-[220px]' />

                    <button className="btn btn-gray flex flex-row justify-center items-center space-x-2 w-[150px]">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span>in process</span>
                    </button>
                </div>
                <div className="flex flex-row justify-between">
                    <div></div>
                    {/* <button type="button" className="btn btn-emp border-2 w-[110px] disabled:bg-gray-100 border-0" disabled={true}>Prev</button> */}
                </div>
            </form>
        </>
    )
}



const Level4 = ({obj}) => {

    return (
        <>
            <div className="flex flex-col space-y-5 justify-between items-center text-center">
                <h1 className="text-3xl font-semibold w-full max-w-[400px]">
                    Your registration has been successfully received!
                </h1>
                <div className="flex justify-center text-lg w-full flex flex-col w-full max-w-[500px]">
                    <span className="">Please wait for registration confirmation</span>
                    <span className="">You will receive an email with your approval</span>
                </div>
                <div className="flex justify-center text-lg w-full flex flex-col w-full max-w-[500px]">
                    <Link to="/" className="font-semibold hover:underline text-[#071278]">Click here to go back to the home page</Link>
                </div>
            </div>
        </>
    )
}



/////// Left items //////

const levels = [
    {
        text: 'Personal information',
        textSm: 'Info'
    },
    {
        text: 'Login details',
        textSm: 'Security'
    },
    {
        text: 'Documents Verification',
        textSm: 'Documents'
    },
    
    {
        text: 'Registration fees',
        textSm: 'Fees'
    }
]


const Li = ({icon, index, level}) => {
    return (
        <li className="flex flex-row items-center space-x-1 lg:space-x-5 text-blue-900">
            <div className="text-2xl bg-white rounded-full p-1">
                {
                    level <= index ? (
                        icon
                    ) : (
                        <FaCheck className="text-emerald-400" />
                    )
                }
            </div>
            <span className="hidden lg:flex">{levels[index].text}</span>
            <span className="lg:hidden">{levels[index].textSm}</span>
        </li>
)
}


/////// FUNCS

const formDataToObject = (formData) => {
    let object = {};
    formData.forEach((value, key) => {
        object[key] = value;
    });
    return object;
};
