import React, { useEffect, useState } from 'react'

export default function RangeSelector({setRange}) {
    const [ranChoosed, setRanChossed] = useState(2)

    useEffect(() => {
        setRange(listRan[ranChoosed].amountDays)
    }, [ranChoosed])
    
  return (
    <div className='bg-[#f0f2f7] flex flex-row rounded-md m-7'>
        {
            listRan.map((item, i) => (
                <Ran key={i} text={item.text} index={i} ranChoosed={ranChoosed} setRanChossed={setRanChossed} />
            ))
        }
    </div>
  )
}

const Ran = ({text, index, ranChoosed, setRanChossed}) => {
    return (
        <div 
            className={`text-gray-500 text-sm font-semibold w-1/4 rounded-md flex justify-center items-center ${index === ranChoosed ? 'bg-white text-blue-600 shadow-md' : 'cursor-pointer'}  m-[3px] py-[10px] w-[58px]`}
            onClick={() => setRanChossed(index)}
        >
            <span>{text}</span>
        </div>
    )
}


const listRan = [
    {
        text: '1w',
        amountDays: 6
    },
    {
        text: '1M',
        amountDays: 30
    },
    {
        text: '1Y',
        amountDays: 365 
    },
    {
        text: 'All',
        amountDays: 500
    },
]