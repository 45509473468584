import React, {useEffect, useState} from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import { GrBitcoin } from 'react-icons/gr';
import DropCoins from '../home/DropCoins'
import {calculatePercentageDifference, formatNumberWithCommas} from '../../lib/funcs'
import { coinsData } from '../../lib/dbInfo'

export default function CoinsSelectior({choosedCoin, setChoosedCoin, data}) {
    const [drop, setDrop] = useState(false)

    const Coin = coinsData[choosedCoin]
    const monDiff = data.length !== 0 ? data[data.length-1].pv - data[0].pv : 0;

  return (
    <div className='flex flex-col space-y-1 min-w-[180px] cursor-pointer' onClick={() => setDrop(prevValue => !prevValue)} id='drop-coins-list'>
        <div className='flex flex-row items-center space-x-[6px]'>
          <Il icon={<Coin.Icon className='text-[22px]' style={{color: Coin.color}} />} text={`${Coin.name} (${Coin.key})`} />
          <IoIosArrowDown className='relative top-0.5' />
        </div>
        <div className='absolute'>
          <DropCoins choosedCoin={choosedCoin} setChoosedCoin={setChoosedCoin} drop={drop} setDrop={setDrop} />
        </div>
        <div className='flex flex-col'>
          <span className='text-[25px] font-semibold'>${data.length !== 0 && formatNumberWithCommas(data[data.length-1].pv)}</span>
          <span className='font-semibold' style={{color: monDiff >= 0 ? '#00994C' : 'red'}}>
            ${data.length > 0 && formatNumberWithCommas((data[data.length-1].pv - data[0].pv).toFixed(2))} ({data.length > 0 && calculatePercentageDifference(data[0].pv, data[data.length-1].pv)})% 
          </span>
        </div>
    </div>
  )
}


function Il({icon, text}) {
    return (
      <div 
          className={`flex flex-row items-center space-x-[6px]`}
      >
          {icon}
          <span className='text-[20px] relative bottom-[1px]'>
              {text}
          </span>
      </div> 
    )
  }
  
  
