import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../lib/AuthContext';
import { postData } from '../../../lib/apiHandler';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { currentUser0, isAdmin0, regLevel0 } from '../../../lib/states';
import HeaderLog from '../HeaderLog';
import { BrandName, Logo } from '../../../App';



const Login = () => {
  const { login } = useAuth();
  const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
  const [isAdmin, setIsAdmin] = useRecoilState(isAdmin0);
  const [errText, setErrText] = useState('')
  const navigate = useNavigate();
  const [regLevel, setRegLevel] = useRecoilState(regLevel0)

  useEffect(() => {
    setIsAdmin(null)
  }, [])

  const handleLogin = async (e) => {
    e.preventDefault()
    setErrText('')
    const result = await postData('/user/login', {
      email: e.target.querySelector('input[name="email"]').value,
      password: e.target.querySelector('input[name="password"]').value,
      fileName: BrandName
    })
    if (result.ok) {
      login(result.data.token, result.data.user.isAdmin);
      setCurrentUser(result.data.user)
      navigate('/home');
    } else {

      if (result.data.level === 2) {
        setRegLevel({level: 2, userId: result.data.userId})
        navigate('/register')
      }
      if (result.data.level === 4) {
        setRegLevel({level: 4, userId: result.data.userId})
        navigate('/register')
      }
      setErrText(result.data.message)
    }


  };

  return (
    <div className="min-h-screen flex flex-col bg-blue-200  p-5 lg:p-10 lg:py-7">
      <div className="cursor-pointer flex flex-row items-center text-white" onClick={() => navigate('/')}>
          <span className={`bg-white rounded-full px-3 py-[2px]`}>
              <Logo color="text-blue-800" />
          </span>
      </div>
      <div className='flex flex-grow items-center justify-center'>
        <form onSubmit={handleLogin} className="max-w-[500px] w-full con p-10 py-7  space-y-6 bg-white flex flex-col items-center justify-between text-[#071278]">
          {/* <img src='/ill_email.png' className='w-[110px]' /> */}
          <h1 className='text-2xl font-semibold'>
            Welcome back to bbit
          </h1>
          <div className="[&>input]:inp space-y-3 w-full">
            <input placeholder="email" name="email" type="email" className='w-full' />
            <input placeholder="password" name="password" type="password" />
          </div>

          <div className='flex flex-row justify-between w-full'>
            <Link to="/forgot" className='font-semibold hover:underline'>Forgot Password?</Link>
            <span className='font-semibold text-red-600'>
              {errText}
            </span>
          </div>

          <button type="submit" className="btn btn-blue bg-blue-600 text-white px-[70px] py-2 h-12 rounded-full">Login</button>
          <div>
            <span className='text-gray-600'>Dont have Account? </span>
            <Link to="/register" className='font-semibold hover:underline'>Register now</Link>
          </div>

        </form>
      </div>

    </div>
  );
};

export default Login;
