import React, { useEffect, useState } from 'react'
import Header from '../../components/parts/Header';
import Noti from '../../components/parts/Noti';
import { useRecoilState } from 'recoil';
import { notiList0, usersList0, messagesAll0 } from '../../lib/states';
import { getData } from '../../lib/apiHandler';
import Pop from '../../components/parts/Pop';


const PanelLayout = ({children}) => {
  const [messagesAll, setMessagesAll] = useRecoilState(messagesAll0)
  const [notiList, setNotiList] = useRecoilState(notiList0)
  const [userList, setUserList] = useRecoilState(usersList0)
  

  const getMessages = async (userId) => {
    const result = await getData(`/chat?userId=${userId}&all=true`, '')
    if (result.ok) {
      //console.log("all Mess", result.data.messages)
      setMessagesAll(result.data.messages)
    } else {
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
  }


  useEffect(() => {
    getMessages();
    const intervalId = setInterval(() => {
      getMessages();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className='bg-gray-100 min-h-screen'>
      <Header toPanel={true} />

      <div className='p-4'>
        {children}
      </div>
      <Pop />
      <Noti />
    </div>
  );
};

export default PanelLayout;
